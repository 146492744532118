import React, { useState, useEffect, useRef } from "react";
import {
    Layout,
    Row,
    Col,
    Form,
    Input,
    Button,
    notification,
    Alert,
    Card,
    Divider
} from "antd";
import { useApp } from "../../../../../AppContext";
import * as WetwheelsApi from "../../../../../client-swagger/api";
import { useForm } from "antd/lib/form/Form";

const AddChildBooking = ({ closeWindow, bookingId }: IAddChildBookingProps) => {

    const {
        authHeaderOptions,
        notifyError,
        notifySuccess,
    } = useApp();

    const [emailFound, setEmailFound] = useState<boolean>(null);
    const emailRef = useRef();

    const checkEmail = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {

        var email = emailRef.current && (emailRef.current as any).input.value;
        console.log(email);

        const { success, message, data }: WetwheelsApi.ApiResponse =
            await new WetwheelsApi.UserApi().clientExists(
                email,
                authHeaderOptions
            );

        setEmailFound(success);

        console.log("success", success);
        // if (!success) {
        //     notifyError(message);
        //     return;
        // }

    }

    const submitForm = async (values: any) => {

        var email = emailRef.current && (emailRef.current as any).input.value;
        const formValues = { ...values };
        formValues.emailAddress = email;

        console.log("formValues", formValues);

        if (!formValues.numberOfPassengers) {
            notifyError("You must specify the number of passengers");
            return;
        }

        if (!formValues.numberOfWheelchairs) {
            formValues.numberOfWheelchairs = 0;
        }
        if (!formValues.numberOfDisabledNonWheelchair) {
            formValues.numberOfDisabledNonWheelchair = 0;
        }

        const { success, message, data }: WetwheelsApi.ApiResponse =
            await new WetwheelsApi.BookingApi().bookingAddChildBookingBookingIdPost(
                formValues,
                bookingId,
                authHeaderOptions
            );

        if (success) {
            notifySuccess("The sub-booking was created. You're currently still viewing the main booking.", `New Booking: ${data.uniqueKey}`);
            closeWindow();
        }
        else {
            notifyError(message);
        }
    }

    return (
        <>
            <h2>Add new sub-booking</h2>
            <Form
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                layout="vertical"
                onFinish={submitForm}
            >
                <Alert
                    type="info"
                    message="Enter the client's email address and click 'Check'. If the client is not found, it can be created with the booking."
                    className="mb-s"
                ></Alert>
                <Form.Item label="Email address" name="emailAddress">
                    <Input.Group compact>
                        <Input size="large" style={{ width: 'calc(100% - 200px)' }} ref={emailRef} defaultValue="" />
                        <Button size="large" type="primary" onClick={(e) => checkEmail(e)}>Check</Button>
                    </Input.Group>
                </Form.Item>
                {emailFound !== null && <>
                    {emailFound
                        ? <Alert type="success" message={(
                            <>
                                <p>The client was found. Please enter the passenger numbers and click 'Add booking'.</p>
                                <p>The normal booking emails will be sent.</p>
                            </>
                        )}></Alert>
                        : <Alert type="warning" message={(
                            <>
                                <p>The client was not found. The user will be created and a password reset email will be sent.</p>
                                <p>Please enter the user's details below.</p>
                                <p>The normal booking emails will be sent.</p>
                            </>
                        )}></Alert>
                    }
                </>}
                <Divider />
                {emailFound !== null && <>
                    {!emailFound
                        ?
                        <>
                            <h3>Client details</h3>
                            <Form.Item label="First name" name="firstName">
                                <Input size="large"></Input>
                            </Form.Item>
                            <Form.Item label="Last name" name="lastName">
                                <Input size="large"></Input>
                            </Form.Item>
                            <Form.Item label="Organisation" name="organisation">
                                <Input size="large"></Input>
                            </Form.Item>
                            <Form.Item label="Phone number" name="phoneNumber">
                                <Input size="large"></Input>
                            </Form.Item>
                            <Divider />
                        </>
                        : null
                    }
                </>}
                {emailFound !== null && <>
                    <h3>Passenger numbers</h3>
                    <Form.Item label="No. of passengers" name="numberOfPassengers">
                        <Input size="large" style={{ width: "300px" }} type="number"></Input>
                    </Form.Item>
                    <Form.Item label="No. of wheelchair passengers" name="numberOfWheelchairs">
                        <Input size="large" style={{ width: "300px" }} type="number"></Input>
                    </Form.Item>
                    <Form.Item label="No. of disabled non-wheelchair passengers" name="numberOfDisabledNonWheelchair">
                        <Input size="large" style={{ width: "300px" }} type="number"></Input>
                    </Form.Item>
                </>}
                <Divider />
                {/* <Row>
                    <Col span={24} className="mb-m">
                        <Alert
                            type="info"
                            message="The new user will be sent an email requiring them to set a password and activate their account."></Alert>
                    </Col>
                </Row> */}
                {emailFound !== null && <Form.Item>
                    <Button type="primary" size="large" htmlType="submit">
                        Add booking
                    </Button>
                </Form.Item>}
            </Form>
        </>
    );
}

export default AddChildBooking;

interface IAddChildBookingProps {
    closeWindow: () => void;
    bookingId: string;
}