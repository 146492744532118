import React from "react";
import User from "../../../enums/User";
import { useApp } from "../../../AppContext";
import DashboardBookingsAdmin from "./Admin/DashboardBookingsAdmin";
import DashboardBookingsSkipperOrCrew from "./Common/DashboardBookingsSkipperOrCrew";
import DashboardBookingClient from "./Client/DashboardBookingsClient";

function DashboardBookings() {
    const { userType } = useApp();

    return (
        <>
            {userType === User.Admin && <DashboardBookingsAdmin />}
            {userType === User.Skipper && <DashboardBookingsSkipperOrCrew />}
            {userType === User.Crew && <DashboardBookingsSkipperOrCrew />}
            {userType === User.Client && <DashboardBookingClient />}
        </>
    );
}

export default DashboardBookings;
