import React, { useEffect } from "react";
import { CalendarProvider } from "../../components/Calendar/CalendarContext";
import Calendar from "../../components/Calendar/Calendar";
import { useBooking } from "../../components/Booking/BookingContext";

function Home() {
    const { setStep } = useBooking();

    useEffect(() => {
        setStep(0);
    }, [])

    return (
        <>
            <CalendarProvider>
                <Calendar />
            </CalendarProvider>
        </>
    );
}

export default Home;
