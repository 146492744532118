import React from "react";
import { Link } from "react-router-dom";
import { Alert, Row } from "antd";

const ManifestAlert = ({ message, button, id, type, page, showButton }: IManifestAlertProps) => {



  return (
    <Alert
      className="mt-s mb-m"
      style={{ width: "100%" }}
      message={message}
      action={showButton && <Link to={`/dashboard/bookings/${id}/${page}`}>
        <button className="a-button v-button-secondary my-xs">
          {button}
        </button>
      </Link>}
      type={type}
    />
  );
};

export default ManifestAlert;

export interface IManifestAlertProps {
  message: any;
  button: string;
  id: string;
  type: "success" | "info" | "warning" | "error";
  page: "manifest" | "disclaimer";
  showButton: boolean;
}
