import React, { useState } from "react";
import { Row, Col, Form, Input, Button, Result } from "antd";
import { Link, useParams } from "react-router-dom";
import { AccountApi, ResetPassword } from "../../../client-swagger";

function ForgotPasswordReset() {
    const [submitted, setSubmitted] = useState<Boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const { resetKey } = useParams<ResetParams>();

    const onFinish = async (value: ResetPassword) => {
        try {
            const register = await new AccountApi().resetPassword(value);
            if (!register.success) {
                setErrorMessage(register.message);
                return;
            }
            setSubmitted(true);
        } catch (err) {
            throw new Error(err);
        }
    };

    return submitted ? (
        <Row className="mt-l mb-l">
            <Col xs={{ span: 24 }}>
                <Result
                    status="success"
                    title="You have successfully reset your password"
                    subTitle="Please login or return to home to continue"
                />

                <Row
                    justify="center"
                    align="middle"
                    className="m-small-screen-stack"
                >
                    <button className="a-button v-button-primary v-button-80 m-s">
                        <Link to="/">Home</Link>
                    </button>

                    <button className="a-button v-button-primary v-button-80 m-s">
                        <Link to="/login">Login</Link>
                    </button>
                </Row>
            </Col>
        </Row>
    ) : (
        <>
            <Row className="mt-l mb-l">
                <Col md={{ span: 14, push: 5 }} xs={{ span: 20, push: 2 }}>
                    <h3>Reset your password</h3>
                    <h4>Your new password must be at least 6 characters</h4>
                    {errorMessage && <p className="error">{errorMessage}</p>}
                    <Form
                        name="basic"
                        initialValues={{ remember: true }}
                        labelCol={{ xs: { span: 12 }, lg: { span: 10 } }}
                        wrapperCol={{ xs: { span: 12 }, lg: { span: 14 } }}
                        onFinish={onFinish}
                        className="o-form"
                    >
                        <Form.Item
                            name="resetKey"
                            className="a-hidden"
                            initialValue={resetKey}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="New password"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your new password!",
                                },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item
                            label="Confirm new password"
                            name="passwordConfirm"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Please confirm your new password!",
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (
                                            !value ||
                                            getFieldValue("password") === value
                                        ) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            "The two passwords that you entered do not match!",
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                className="a-button v-button-primary"
                                type="primary"
                                htmlType="submit"
                            >
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </>
    );
}

export default ForgotPasswordReset;

type ResetParams = {
    resetKey: string;
};
