import React, { useState, useEffect } from "react";
import { Layout, Row, Col, Table, Popconfirm, notification } from "antd";
import { Redirect } from "react-router-dom";
import * as WetwheelsApi from "../../../client-swagger/api";
import { useApp } from "../../../AppContext";
import { ApiResponse } from "../../../client-swagger/api";

const DashboardEmailQueue = () => {
  const [redirect] = useState<boolean>(false);
  const [list, setList] = useState();
  const [, setIsListLoading] = useState<boolean>(true);
  const { authHeaderOptions } = useApp();

  useEffect(() => {
    new WetwheelsApi.EmailApi()
      .getEmailTemplates(authHeaderOptions)
      .then(({ code, data }: ApiResponse) => {
        if (code === 200) {
          setList(data);
        }
        setIsListLoading(false);
      });
  }, []);

  let disable = false;

  const handleDisableClick = () => {
    disable = true;
  };

  function resetDisable(success: boolean) {
    if (success) {
      notification.success({
        message: "Success",
        description: "Your email config has been deleted",
      });
    }

    setTimeout(() => {
      disable = false;
    }, 1);
  }
  const emailColumns = [
    {
      title: "Key",
      dataIndex: "key",
      key: "emailKey",
    },
    {
      title: "Name",
      dataIndex: "friendlyName",
      key: "emailName",
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      render: () => (
        <>
          <Popconfirm
            title="Are you sure"
            cancelText="no"
            okText="yes"
            onConfirm={() => {
              resetDisable(true);
            }}
            onCancel={() => {
              resetDisable(false);
            }}>
            <button
              className="a-button v-button-warning"
              onClick={handleDisableClick}>
              Delete
            </button>
          </Popconfirm>
        </>
      ),
      width: "180px",
    },
  ];

  function handleRow(record: any) {
    return {
      onClick: () => {
        if (disable) return;
        const ref = `/dashboard/email/detail?id=${record.id}`;
        window.location.href = ref;
      },
    };
  }

  return redirect ? (
    <Redirect to="/dashboard/email/detail?new=true" />
  ) : (
    <>
      <Layout>
        <Layout.Header
          className="mb-m"
          style={{ padding: 0, background: "none", width: "100%" }}>
          <h2>Queued emails</h2>
        </Layout.Header>
        <Layout.Content>
          <Row gutter={[16, 16]}>
            <Col md={{ span: 24 }}>
              <Table
                rowKey={"key"}
                onRow={handleRow}
                columns={emailColumns}
                dataSource={list}
                pagination={false}
              />
            </Col>
          </Row>
        </Layout.Content>
        {/* <Layout.Footer
          style={{ padding: 0, background: "none", width: "100%" }}
        >
          <button
            className="a-button v-button-primary"
            onClick={handleAddEmail}
          >
            Add new email
          </button>
        </Layout.Footer> */}
      </Layout>
    </>
  );
};

export default DashboardEmailQueue;
