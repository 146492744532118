import React from "react";
import { Layout, Row, Col } from "antd";

const PrivacyPolicy = () => {
  return (
    <Row style={{ marginTop: "-8rem" }}>
      <Col
        xs={{ span: 22, offset: 1 }}
        // style={{
        //   color: "black",
        //   width: "90vw",
        //   display: "flex",
        //   flexDirection: "column",
        //   alignItems: "center",
        // }}
      >
        <p>
          <h3>
            <strong>Website Privacy Policy</strong>
          </h3>
        </p>

        <p>
          Wetwheels is committed to protecting your privacy, and at the same
          time we endeavour to use any data we collect from you to provide the
          best possible service. This privacy policy sets out the basis on which
          any personal data we collect from you online, or that you provide to
          us online, will be processed by us. Please read the following
          carefully to understand our views and practices regarding your
          personal data and how we will treat it.
        </p>

        <p>
          This site is a UK based website and takes reasonable care to comply
          with requirements of the General Data Protection Regulation
          (Regulation (EU) 2016/679) with regard to the personal information you
          supply on this site.
        </p>

        <p>
          For the purpose of the GDPR (the "<strong>Act</strong>"), the data
          controller is <strong>Wetwheels Foundation</strong> of The Victory,
          Gunwharf Quays House, Gunwharf Quays, Portsmouth PO1 3TZ. Our
          nominated representative for the purpose of the Act is{" "}
          <strong>Andy Fell</strong>.
        </p>

        <p>
          No data transmissions over the Internet can be guaranteed to be 100%
          secure, we cannot ensure or warrant the security of any information
          you transmit to us and you do so at your own risk.
        </p>

        <p>
          Wetwheels may occasionally modify this Website Privacy Policy, such
          variations becoming effective immediately upon posting to the website
          and by continuing to use the website, you will be deemed to accept any
          such variations.
        </p>

        <p>
          Please note that Wetwheels may from time to time provide links to
          other sites, which may not be governed by this Website Privacy Policy
          and you should view the particular privacy policies of those sites for
          further information.
        </p>

        <p>
          <h4>
            <u>DATA COLLECTION</u>
          </h4>
        </p>

        <p>We will collect and process the following data about you:</p>

        <ul>
          <li>
            <strong>Information you give us.</strong> This is information about
            you that you give us by filling in forms on our site{" "}
            <a href="http://www.wetwheelsfoundation.org/">
              http://www.wetwheelsfoundation.org/
            </a>{" "}
            ("<strong>our site</strong>"). It includes information you provide
            when you register to use our site, request marketing information,
            sign up to our newsletter, contact us, pay a bill and make a
            donation. The information you give us may include your name,
            address, e-mail address and phone number, age, gender, PayPal
            address, confirmation of whether you are or a member of your group
            is a wheelchair user and whether you have a disability.
          </li>
          <li>
            <strong>Information we collect about you.</strong> With regard to
            each of your visits to our site we may automatically collect but do
            not actively monitor, technical information, including the Internet
            protocol (IP) address used to connect your computer to the Internet.
          </li>
        </ul>

        <p>
          Any of the data above might include "special categories" of more
          sensitive personal information, which is Information about your
          health, including any medical conditions that we may need to consider
          when booking your trip.
        </p>

        <p>
          We do not need your consent to process personal data where we have a
          legitimate business interest in doing so and/or where we are
          processing your personal data in order to fulfill a contractual
          obligation with you. However, where we collect special categories of
          data, we will need your consent to process this personal data and we
          will ask for it on the appropriate form on which we gather this
          information.
        </p>

        <p>
          You submit information using these methods voluntarily, and agree to
          the use of the personal data you provide as described in this policy.
          If you give us personal information about somebody else, such as
          someone in your care, we will assume you have their permission to do
          so, and their data will also be subject to this policy.
        </p>

        <p>
          <strong>If you fail to provide personal information</strong>
        </p>

        <p>
          If you fail to provide certain information when requested, we may not
          be able to perform the contract we have entered into with you, or we
          may be prevented from complying with our legal obligations (such as to
          ensure the health and safety of our volunteers and service users).
        </p>

        <p>
          <strong>Data collection via cookies</strong>
        </p>

        <p>
          Wetwheels may also collect personally identifying information using
          cookies. Sometimes cookies are required as part of this site’s
          operation. We will not collect personal data about you using cookies
          outside of this purpose. If you prefer not to allow the use of
          cookies, you can change the configuration of your browser to either
          warn you when you receive a cookie, or to automatically reject them.
          You may find, however, that disabling cookies means certain areas of
          the site do not function correctly.
        </p>

        <p>
          <h4>
            <u>DATA USAGE</u>
          </h4>
        </p>

        <p>We use information held about you in the following ways:</p>

        <p>
          <strong>Information you give to us.</strong> We will use this
          information:
        </p>

        <ul>
          <li>
            To carry out our obligations arising from any bookings or contracts
            entered into between you and us and to provide you with the
            information and services that you request from us;
          </li>
          <li>
            Carrying out marketing and fundraising activities to promote the
            aims and ideals of Wetwheels Foundation;
          </li>
          <li>
            To provide you with information about other services we offer that
            are similar to those that you have already purchased or enquired
            about;
          </li>
          <li>To notify you about changes to our service;</li>
          <li>
            To ensure that content from our site is presented in the most
            effective manner for you and for your computer.
          </li>
        </ul>

        <p> </p>

        <p>
          <strong>Information we collect about you.</strong> We will use this
          information:
        </p>

        <ul>
          <li>
            To administer our site and for internal operations, including
            troubleshooting, data analysis, testing, research, statistical and
            survey purposes;
          </li>
          <li>
            To improve our site to ensure that content is presented in the most
            effective manner for you and for your computer;
          </li>
          <li>
            To allow you to participate in interactive features of our service,
            when you choose to do so;
          </li>
          <li>As part of our efforts to keep our site safe and secure.</li>
        </ul>

        <p>
          <h4>
            <u>DISCLOSURE OF YOUR INFORMATION</u>
          </h4>
        </p>

        <p>
          You agree that we have the right to share your personal information
          with any member of our Wetwheels group, which means the Wetwheels
          Foundation and other Wetwheels Operators.
        </p>

        <p>We will disclose your personal information to third parties:</p>

        <ul>
          <li>
            In the event that we sell or buy any business or assets, in which
            case we will disclose your personal data to the prospective seller
            or buyer of such business or assets.
          </li>
          <li>
            If we are under a duty to disclose or share your personal data in
            order to comply with any legal obligation(including but not limited
            to safeguarding), or in order to enforce or apply our Terms and
            Conditions and other agreements; or to protect the rights, property,
            or safety of Wetwheels, our participants, or others. This includes
            exchanging information with other companies and organisations for
            the purposes of fraud protection and credit risk reduction.
          </li>
        </ul>

        <p>
          <strong>Where we store your personal data</strong>
        </p>

        <p>
          The data that we collect from you will not, to the best of our
          knowledge, information and belief, be transferred to, and stored at, a
          destination outside the European Economic Area ("EEA"). However, we do
          use Dropbox and Google Drive, both of which, the EU has decided, have
          adequate levels of data protection in place.
        </p>

        <p>
          All information you provide to us is stored on our secure servers or
          those of our hosting provider. At some point in the future we may
          choose to transfer our IT systems to a reputable cloud based provider.
          We will ensure that any such transfer will include appropriate
          safeguards in the legal documentation.{" "}
        </p>

        <p>
          <h4>
            <u>DATA RETENTION</u>
          </h4>
        </p>

        <p>
          We will only retain your personal information for as long as necessary
          to fulfil the purposes we collected it for, including for the purposes
          of satisfying any legal, accounting, or reporting requirements. To
          determine the appropriate retention period for personal data, we
          consider the amount, nature, and sensitivity of the personal data, the
          potential risk of harm from unauthorised use or disclosure of your
          personal data, the purposes for which we process your personal data
          and whether we can achieve those purposes through other means, and the
          applicable legal requirements.
        </p>

        <p>
          Once we are no longer legally required to retain your personal data we
          will retain and securely destroy your personal information.
        </p>

        <p>
          <h4>
            <u>RIGHTS OF ACCESS, CORRECTION, ERASURE, AND RESTRICTION </u>
          </h4>
        </p>

        <p>
          <strong>Your duty to inform us of changes</strong>
        </p>

        <p>
          It is important that the personal information we hold about you is
          accurate and current. Please keep us informed if your personal
          information changes during your working relationship with us.
        </p>

        <p>
          <strong>Your rights in connection with personal information</strong>
        </p>

        <p>Under certain circumstances, by law you have the right to:</p>

        <ul>
          <li>
            Request access to your personal information (commonly known as a
            "data subject access request"). This enables you to receive a copy
            of the personal information we hold about you and to check that we
            are lawfully processing it.
          </li>
          <li>
            Request correction of the personal information that we hold about
            you. This enables you to have any incomplete or inaccurate
            information we hold about you corrected.
          </li>
          <li>
            Request the erasure of your personal information. This enables you
            to ask us to delete or remove personal information where there is no
            lawful reason for us continuing to process it. You also have the
            right to ask us to stop processing personal information where we are
            relying on a legitimate interest and there is something about your
            particular situation, which makes you want to object to processing
            on this ground.
          </li>
          <li>
            You have the right to ask us not to process your personal data for
            marketing purposes. We will inform you (before collecting your data)
            if we intend to use your data for such. You can exercise your right
            to prevent such processing by checking certain boxes on the forms we
            use to collect your data.{" "}
          </li>
          <li>
            Request the restriction of processing of your personal information.
            This enables you to ask us to suspend the processing of personal
            information about you, for example if you want us to establish its
            accuracy or the reason for processing it.
          </li>
          <li>
            Request the transfer of your personal information to another party.
          </li>
        </ul>

        <p>
          <strong>No fee usually required</strong>
        </p>

        <p>
          You will not have to pay a fee to access your personal information (or
          to exercise any of the other rights). However, we may charge a
          reasonable fee in respect of our admin costs in complying with your
          request. If your request for access is clearly unfounded or excessive,
          we may refuse to comply with the request in such circumstances.
        </p>

        <p>
          <h4>
            <u>RIGHT TO WITHDRAW CONSENT</u>
          </h4>
        </p>

        <p>
          In the limited circumstances where you may have provided your consent
          to the collection, processing and transfer of your personal
          information for a specific purpose, you have the right to withdraw
          your consent for that specific processing at any time. To withdraw
          your consent, please contact{" "}
          <a href="mailto:andy@wetwheelsfoundation.org">
            <strong>andy@wetwheelsfoundation.org</strong>
          </a>
          . Once we have received notification that you have withdrawn your
          consent, we will no longer process your information for the purpose or
          purposes you originally agreed to, unless we have another legitimate
          basis for doing so in law.
        </p>

        <p>
          <h4>
            <u>CHANGES TO OUR WEBSITE POLICY</u>
          </h4>
        </p>

        <p>
          Any changes we make to our privacy policy in the future will be posted
          on this page and, where appropriate, notified to you by e-mail. Please
          check back frequently to see any updates or changes to our privacy
          policy.
        </p>

        <p>
          <h4>
            <u>CONTACT</u>
          </h4>
        </p>

        <p>
          Questions, comments and requests regarding this privacy policy are
          welcomed and should be addressed to{" "}
          <a href="mailto:andy@wetwheelsfoundation.org">
            <strong>andy@wetwheelsfoundation.org</strong>
          </a>
          <strong>.</strong>
        </p>

        {/* <h3>Privacy Policy</h3>

        <h4>Website Privacy Policy</h4>
        <p>
          Wetwheels is committed to protecting your privacy, and at the same
          time we endeavour to use any data we collect from you to provide the
          best possible service. This privacy policy sets out the basis on which
          any personal data we collect from you online, or that you provide to
          us online, will be processed by us. Please read the following
          carefully to understand our views and practices regarding your
          personal data and how we will treat it.
        </p>
        <p>
          This site is a UK based website and takes reasonable care to comply
          with requirements of the General Data Protection Regulation
          (Regulation (EU) 2016/679) with regard to the personal information you
          supply on this site.
        </p>
        <p>
          For the purpose of the GDPR (the "Act"), the data controller is
          Wetwheels Foundation of The Victory, Gunwharf Quays House, Gunwharf
          Quays, Portsmouth PO1 3TZ. Our nominated representative for the
          purpose of the Act is Andy Fell.
        </p>
        <p>
          No data transmissions over the Internet can be guaranteed to be 100%
          secure, we cannot ensure or warrant the security of any information
          you transmit to us and you do so at your own risk.
        </p>
        <p>
          Wetwheels may occasionally modify this Website Privacy Policy, such
          variations becoming effective immediately upon posting to the website
          and by continuing to use the website, you will be deemed to accept any
          such variations.
        </p>
        <p>
          Please note that Wetwheels may from time to time provide links to
          other sites, which may not be governed by this Website Privacy Policy
          and you should view the particular privacy policies of those sites for
          further information.
        </p>
        <h4>DATA COLLECTION</h4>
        <p>We will collect and process the following data about you:</p>
        <p>
          Information you give us. This is information about you that you give
          us by filling in forms on our site http://www.wetwheelsfoundation.org/
          ("our site"). It includes information you provide when you register to
          use our site, request marketing information, sign up to our
          newsletter, contact us, pay a bill and make a donation. The
          information you give us may include your name, address, e-mail address
          and phone number, age, gender, PayPal address, confirmation of whether
          you are or a member of your group is a wheelchair user and whether you
          have a disability. Information we collect about you. With regard to
          each of your visits to our site we may automatically collect but do
          not actively monitor, technical information, including the Internet
          protocol (IP) address used to connect your computer to the Internet.
          Any of the data above might include "special categories" of more
          sensitive personal information, which is Information about your
          health, including any medical conditions that we may need to consider
          when booking your trip.
        </p>
        <p>
          We do not need your consent to process personal data where we have a
          legitimate business interest in doing so and/or where we are
          processing your personal data in order to fulfill a contractual
          obligation with you. However, where we collect special categories of
          data, we will need your consent to process this personal data and we
          will ask for it on the appropriate form on which we gather this
          information.
        </p>
        <p>
          You submit information using these methods voluntarily, and agree to
          the use of the personal data you provide as described in this policy.
          If you give us personal information about somebody else, such as
          someone in your care, we will assume you have their permission to do
          so, and their data will also be subject to this policy.
        </p>
        <p>If you fail to provide personal information</p>
        <p>
          If you fail to provide certain information when requested, we may not
          be able to perform the contract we have entered into with you, or we
          may be prevented from complying with our legal obligations (such as to
          ensure the health and safety of our volunteers and service users).
        </p>
        <p>Data collection via cookies</p>
        <p>
          Wetwheels may also collect personally identifying information using
          cookies. Sometimes cookies are required as part of this site’s
          operation. We will not collect personal data about you using cookies
          outside of this purpose. If you prefer not to allow the use of
          cookies, you can change the configuration of your browser to either
          warn you when you receive a cookie, or to automatically reject them.
          You may find, however, that disabling cookies means certain areas of
          the site do not function correctly.
        </p>
        <h4>DATA USAGE</h4>
        <p>We use information held about you in the following ways:</p>
        <p>Information you give to us. We will use this information:</p>
        <p>
          To carry out our obligations arising from any bookings or contracts
          entered into between you and us and to provide you with the
          information and services that you request from us; <br />
          Carrying out marketing and fundraising activities to promote the aims
          and ideals of Wetwheels Foundation;
          <br />
          To provide you with information about other services we offer that are
          similar to those that you have already purchased or enquired about;
          <br />
          To notify you about changes to our service;
          <br />
          To ensure that content from our site is presented in the most
          effective manner for you and for your computer.
          <br />
        </p>
        <p>Information we collect about you. We will use this information:</p>
        <p>
          To administer our site and for internal operations, including
          troubleshooting, data analysis, testing, research, statistical and
          survey purposes;
          <br />
          To improve our site to ensure that content is presented in the most
          effective manner for you and for your computer;
          <br />
          To allow you to participate in interactive features of our service,
          when you choose to do so;
          <br />
          As part of our efforts to keep our site safe and secure.
          <br />
        </p>
        <h4>DISCLOSURE OF YOUR INFORMATION</h4>
        <p>
          You agree that we have the right to share your personal information
          with any member of our Wetwheels group, which means the Wetwheels
          Foundation and other Wetwheels Operators.
        </p>
        <p></p>
        <p>We will disclose your personal information to third parties:</p>
        <p>
          In the event that we sell or buy any business or assets, in which case
          we will disclose your personal data to the prospective seller or buyer
          of such business or assets. If we are under a duty to disclose or
          share your personal data in order to comply with any legal
          obligation(including but not limited to safeguarding), or in order to
          enforce or apply our Terms and Conditions and other agreements; or to
          protect the rights, property, or safety of Wetwheels, our
          participants, or others. This includes exchanging information with
          other companies and organisations for the purposes of fraud protection
          and credit risk reduction. Where we store your personal data
        </p>
        <p>
          The data that we collect from you will not, to the best of our
          knowledge, information and belief, be transferred to, and stored at, a
          destination outside the European Economic Area ("EEA"). However, we do
          use Dropbox and Google Drive, both of which, the EU has decided, have
          adequate levels of data protection in place.
        </p>
        <p>
          All information you provide to us is stored on our secure servers or
          those of our hosting provider. At some point in the future we may
          choose to transfer our IT systems to a reputable cloud based provider.
          We will ensure that any such transfer will include appropriate
          safeguards in the legal documentation.
        </p>

        <h4>DATA RETENTION</h4>
        <p>
          We will only retain your personal information for as long as necessary
          to fulfil the purposes we collected it for, including for the purposes
          of satisfying any legal, accounting, or reporting requirements. To
          determine the appropriate retention period for personal data, we
          consider the amount, nature, and sensitivity of the personal data, the
          potential risk of harm from unauthorised use or disclosure of your
          personal data, the purposes for which we process your personal data
          and whether we can achieve those purposes through other means, and the
          applicable legal requirements.
        </p>
        <p>
          Once we are no longer legally required to retain your personal data we
          will retain and securely destroy your personal information.
        </p>

        <h4>RIGHTS OF ACCESS, CORRECTION, ERASURE, AND RESTRICTION</h4>

        <p>Your duty to inform us of changes</p>

        <p>
          It is important that the personal information we hold about you is
          accurate and current. Please keep us informed if your personal
          information changes during your working relationship with us.
        </p>
        <p>Your rights in connection with personal information</p>
        <p>Under certain circumstances, by law you have the right to:</p>

        <p>
          Request access to your personal information (commonly known as a "data
          subject access request"). This enables you to receive a copy of the
          personal information we hold about you and to check that we are
          lawfully processing it. Request correction of the personal information
          that we hold about you. This enables you to have any incomplete or
          inaccurate information we hold about you corrected. Request the
          erasure of your personal information. This enables you to ask us to
          delete or remove personal information where there is no lawful reason
          for us continuing to process it. You also have the right to ask us to
          stop processing personal information where we are relying on a
          legitimate interest and there is something about your particular
          situation, which makes you want to object to processing on this
          ground. You have the right to ask us not to process your personal data
          for marketing purposes. We will inform you (before collecting your
          data) if we intend to use your data for such. You can exercise your
          right to prevent such processing by checking certain boxes on the
          forms we use to collect your data. Request the restriction of
          processing of your personal information. This enables you to ask us to
          suspend the processing of personal information about you, for example
          if you want us to establish its accuracy or the reason for processing
          it. Request the transfer of your personal information to another
          party. <br />
          No fee usually required
        </p>
        <p>
          You will not have to pay a fee to access your personal information (or
          to exercise any of the other rights). However, we may charge a
          reasonable fee in respect of our admin costs in complying with your
          request. If your request for access is clearly unfounded or excessive,
          we may refuse to comply with the request in such circumstances.
        </p>

        <h4>RIGHT TO WITHDRAW CONSENT</h4>

        <p>
          In the limited circumstances where you may have provided your consent
          to the collection, processing and transfer of your personal
          information for a specific purpose, you have the right to withdraw
          your consent for that specific processing at any time. To withdraw
          your consent, please contact andy@wetwheelsfoundation.org. Once we
          have received notification that you have withdrawn your consent, we
          will no longer process your information for the purpose or purposes
          you originally agreed to, unless we have another legitimate basis for
          doing so in law.
        </p>

        <h4>CHANGES TO OUR WEBSITE POLICY</h4>

        <p>
          Any changes we make to our privacy policy in the future will be posted
          on this page and, where appropriate, notified to you by e-mail. Please
          check back frequently to see any updates or changes to our privacy
          policy.
        </p>

        <h4>CONTACT</h4>

        <p>
          Questions, comments and requests regarding this privacy policy are
          welcomed and should be addressed to andy@wetwheelsfoundation.org.
        </p> */}
      </Col>
    </Row>
  );
};

export default PrivacyPolicy;
