import React, { useState } from "react";
import { Layout, Row, Col, Button, Select, DatePicker, Space } from "antd";
import Report from "../../../enums/ReportType";
import * as WetwheelsApi from "../../../client-swagger/api";
import { useApp } from "../../../AppContext";
import moment from "moment";

function DashboardReports() {
  const [startDate, setStartDate] = useState<Date>(null);
  const [endDate, setEndDate] = useState<Date>(null);
  const [report, setReport] = useState<Report>(Report.None);
  const { authHeaderOptions } = useApp();
  const { RangePicker } = DatePicker;

  const handleReportChange = (value: Report) => {
    setReport(value);
  };

  const downloadReport = async (report: Report, startDate: Date, endDate: Date) => {
    const rType = enumText(report);
    const {
      success,
      message,
      data,
    } = await new WetwheelsApi.ReportApi().reportBookingsBookingStatusStartDateEndDateGet(
      moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).format("YYYY-MM-DD"),
      rType,
      authHeaderOptions
    );
    if (success) {
      downloadCsv(data, report, message);
    }
  };

  const handleExport = async () => {
    await downloadReport(report, startDate, endDate);
  };

  const enumText = (report: Report) => {
    const reportText = Report[report];
    return reportText;
  };

  const downloadCsv = (
    csv: string,
    report: Report,
    fileName: string
  ) => {
    const linkSource = `data:text/csv;base64,${csv}`;
    const downloadLink = document.createElement("a");

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  return (
    <>
      <Layout>
        <Layout.Header
          className="mb-m"
          style={{ padding: 0, background: "none", width: "100%" }}>
          <h2>Reports</h2>
        </Layout.Header>
        <Layout.Content>
          <Row gutter={[16, 16]}>
            <Col md={{ span: 24 }}>
              <h3>Export bookings</h3>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col md={{ span: 12 }}>
              <p>Choose the start and end date (inclusive), then select the booking status to get the report</p>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col md={{ span: 12 }}>
              <RangePicker onChange={(dates: [moment.Moment, moment.Moment], dateStrings: [string, string]) => {
                setStartDate(dates[0].toDate());
                setEndDate(dates[1].toDate());
              }} />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col md={{ span: 6 }}>
              <Select
                defaultValue={Report.None}
                style={{ width: "100%" }}
                onChange={handleReportChange}>
                <Select.Option value={Report.None}>
                  -- Select booking status --
                </Select.Option>
                <Select.Option value={Report.All}>All</Select.Option>
                <Select.Option value={Report.Confirmed}>
                  Confirmed
                </Select.Option>
                <Select.Option value={Report.Unconfirmed}>
                  Unconfirmed
                </Select.Option>
                <Select.Option value={Report.Cancelled}>
                  Cancelled
                </Select.Option>
              </Select>
            </Col>
          </Row>
          <Row gutter={[16, 52]}>
            <Col md={{ span: 24 }}>
              <Button
                className="a-button v-button-primary"
                disabled={report === Report.None}
                onClick={handleExport}>
                Download
              </Button>
            </Col>
          </Row>


        </Layout.Content>
      </Layout>
    </>
  );
}

export default DashboardReports;
