import React from "react";
import { Col } from "antd";
import { useCalendar } from "./CalendarContext";
import moment from "moment";

function Time({ start, end, available, index }: ITimeProps) {
    const { handleTimeClick, handleTimeHover, handleTimeHoverOut } =
        useCalendar();

    return (
        <>
            <Col
                md={6}
                xs={12}
                className={`p-m m-time ${available ? "" : "disabled"}`}
            >
                <p
                    className={`p-xxs`}
                    data-time-start={start}
                    data-time-end={end}
                    data-index={index}
                    onClick={handleTimeClick}
                    onMouseEnter={handleTimeHover}
                    onMouseLeave={handleTimeHoverOut}
                >
                    {moment(start).format("h:mm a")}
                </p>
            </Col>
        </>
    );
}

export default Time;

interface ITimeProps {
    start: string;
    end: string;
    available: boolean;
    index: number;
}
