import React, { useState } from "react";
import { Form, Input, Button, Col, Row } from "antd";
import { Redirect } from "react-router";
import { AccountApi, CreateUser } from "../../../client-swagger/api";
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha,
} from "react-google-recaptcha-v3";

function Register() {
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    let captchaResponse = "";

    const onFinish = async (value: CreateUser) => {
        value.googleReCaptchaResponse = captchaResponse;
        const register = await new AccountApi().accountSignup(value);
        console.log(
            "🚀 ~ file: Register.tsx:18 ~ onFinish ~ register:",
            register,
        );
        if (!register.success) {
            setErrorMessage(register.message);
            return;
        }

        setSubmitted(true);
    };
    const onFinishFailed = () => {};

    const handleRecaptchaVerify = (value: string) => {
        captchaResponse = value;
    };

    const googleRecaptchaSiteKey = process.env.GOOGLE_RECAPTCHA_SITE_KEY;

    return submitted ? (
        <Redirect to="/login/register/email" />
    ) : (
        <>
            <Row className="mt-l mb-l">
                <Col md={{ span: 12, push: 6 }} xs={{ span: 20, push: 2 }}>
                    <h3>Register</h3>
                    {errorMessage && <p className="error">{errorMessage}</p>}

                    <Form
                        name="basic"
                        labelCol={{ span: 12 }}
                        wrapperCol={{ span: 12 }}
                        initialValues={{ remember: true, organisation: "" }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        className="o-form"
                    >
                        <Form.Item
                            label="First name"
                            name="firstName"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter your first name!",
                                },
                            ]}
                        >
                            <Input size="large" />
                        </Form.Item>

                        <Form.Item
                            label="Last name"
                            name="lastName"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter your last name!",
                                },
                            ]}
                        >
                            <Input size="large" />
                        </Form.Item>

                        <Form.Item
                            label="Organisation"
                            name="organisation"
                            rules={[
                                {
                                    required: false,
                                    message: "Please enter your organisation!",
                                },
                            ]}
                        >
                            <Input size="large" />
                        </Form.Item>

                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your email!",
                                },
                            ]}
                        >
                            <Input className="col-16" size="large" />
                        </Form.Item>
                        <Form.Item
                            label="Contact Number"
                            name="contactNumber"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Please enter your contact number!",
                                },
                            ]}
                        >
                            <Input className="col-16" size="large" />
                        </Form.Item>
                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your password!",
                                },
                            ]}
                        >
                            <Input.Password size="large" />
                        </Form.Item>

                        <Form.Item
                            label="Confirm password"
                            name="confirmPassword"
                            rules={[
                                {
                                    required: true,
                                    message: "Please confirm your password!",
                                },
                            ]}
                        >
                            <Input.Password size="large" />
                        </Form.Item>

                        <GoogleReCaptchaProvider
                            reCaptchaKey={googleRecaptchaSiteKey}
                        >
                            <GoogleReCaptcha onVerify={handleRecaptchaVerify} />
                        </GoogleReCaptchaProvider>

                        <Row justify="center">
                            <Button
                                className="px-l mt-m"
                                type="primary"
                                size="large"
                                htmlType="submit"
                            >
                                Submit
                            </Button>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </>
    );
}

export default Register;
