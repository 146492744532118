import React, { useState, useEffect } from "react";
import { Layout, Row, Col, Table } from "antd";
import { Redirect } from "react-router-dom";
import * as WetwheelsApi from "../../../client-swagger/api";
import { useApp } from "../../../AppContext";
import { ApiResponse } from "../../../client-swagger/api";

function DashboardEmailConfig() {
  const [redirect] = useState<boolean>(false);
  const [list, setList] = useState();
  const [, setIsListLoading] = useState<boolean>(true);
  const { authHeaderOptions } = useApp();

  useEffect(() => {
    new WetwheelsApi.EmailApi()
      .getEmailTemplates(authHeaderOptions)
      .then(({ code, data }: ApiResponse) => {
        if (code === 200) {
          setList(data);
        }
        setIsListLoading(false);
      });
  }, []);

  const emailColumns = [
    {
      title: "Name",
      dataIndex: "friendlyName",
      key: "emailName",
    },
    {
      title: "Key",
      dataIndex: "key",
      key: "emailKey",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
  ];

  function handleRow(record: any) {
    return {
      onClick: () => {
        const ref = `/dashboard/emailtemplates/${record.id}`;
        window.location.href = ref;
      },
    };
  }

  return redirect ? (
    <Redirect to="/dashboard/email/detail?new=true" />
  ) : (
    <>
      <Layout>
        <Layout.Header
          className="mb-m"
          style={{ padding: 0, background: "none", width: "100%" }}>
          <h2>Email templates</h2>
        </Layout.Header>
        <Layout.Content>
          <Row gutter={[16, 16]}>
            <Col md={{ span: 24 }}>
              <Table
                rowKey={"key"}
                onRow={handleRow}
                columns={emailColumns}
                dataSource={list}
                pagination={false}
                style={{ wordBreak: "break-word" }}
              />
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
    </>
  );
}

export default DashboardEmailConfig;
