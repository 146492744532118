import { Alert, Button, Card, Col, Descriptions, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import User from "../../../../enums/User";

const ClientDetails = ({ clientUser, userType }: any) => {

    const [, setShowDrawer] = useState<boolean>(false);
    const handleClientViewClick = () => {
        setShowDrawer(true);
    };

    return clientUser && userType && (
        <Card type={"inner"}>
            <h4>Client details</h4>
            <Descriptions>
                <Descriptions.Item span={24} label="Name">
                    {clientUser.firstName}  {clientUser.lastName}
                </Descriptions.Item>
                <Descriptions.Item span={24} label="Email address">
                    {clientUser.email}
                </Descriptions.Item>
                <Descriptions.Item span={24} label="Phone number">
                    {clientUser.contactNumber}
                </Descriptions.Item>
            </Descriptions>
            {userType === User.Admin && (
                <Link
                    to={`/dashboard/clients/${clientUser.id}`}
                    onClick={() => handleClientViewClick()}
                >
                    <Button type="primary">View Client</Button>
                </Link>
            )}




        </Card>
    )
}

export default ClientDetails;
