import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <footer className="o-footer">
        <ul className="footer-list">
          <Link to="/privacy">
            <li className="footer-li footer-pipe">Privacy Policy</li>
          </Link>
          <Link to="/legal">
            <li className="footer-li footer-pipe"> Terms &amp; Conditions</li>
          </Link>
          <li className="footer-li footer-pipe">
            © { new Date().getFullYear() } Wetwheels all rights reserved
          </li>
          <li className="footer-li">
            <a
              href="https://www.webreality.co.uk/"
              target="_blank"
              rel="noopener noreferrer">
              Site by Webreality
            </a>
          </li>
        </ul>
      </footer>
    </>
  );
}

export default Footer;
