import React from "react";

function Banner() {
  return (
    <>
      <div
        className="o-hero-wrapper"
        style={{
          background: "url(./images/Banner/WWJCastle.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}>
        <div className="fw">
          <div className="pw">
            <div className="content">
              <h1 className="banner">
                Wetwheels Jersey
                <i>Serving Jersey and the Channel Islands</i>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Banner;
