import React from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import MainLayout from "./components/MainLayout";
import Login from "./Views/Login/Login";
import Register from "./Views/Login/Register/Register";
import "antd/dist/antd.css";
import "./styles/main.scss";
import ForgotPassword from "./Views/Login/ForgotPassword/ForgotPassword";
import DashboardRoute from "./Views/Dashboard/DashboardRoute";
import HomeRouter from "./Views/Home/HomeRouter";
import ReviewBooking from "./Views/Home/ReviewBooking";
import ConfirmBooking from "./Views/Home/ConfirmBooking";
import { BookingProvider } from "./components/Booking/BookingContext";
import RegisterEmail from "./Views/Login/Register/RegisterEmail";
import ConfirmEmail from "./Views/Login/ConfirmEmail";
import ForgotPasswordReset from "./Views/Login/ForgotPassword/ForgotPasswordReset";
import PrivacyPolicy from "./components/PrivacyPolicy";
import LegalDisclaimer from "./components/LegalDisclaimer";
import ManifestPrintRoute from "./Views/Dashboard/ManifestPrintRoute";

import PrivateRoute from "./components/Util/PrivateRoute";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { faCheckSquare, faCoffee } from "@fortawesome/free-solid-svg-icons";

library.add(fab, faCheckSquare, faCoffee);

function App() {
    return (
        <BrowserRouter>
            <Switch>
                <PrivateRoute path="/dashboard" component={DashboardRoute} />
                <PrivateRoute path="/print" component={ManifestPrintRoute} />

                <MainLayout>
                    <Route
                        exact
                        path="/login/forgotPassword"
                        component={ForgotPassword}
                    />
                    <Route exact path="/login/register" component={Register} />
                    <Route
                        exact
                        path="/login/register/email"
                        component={RegisterEmail}
                    />
                    <Route
                        exact
                        path="/login/confirm/:userId"
                        component={ConfirmEmail}
                    />
                    <Route
                        exact
                        path="/login/resetpassword/:resetKey"
                        component={ForgotPasswordReset}
                    />
                    <Route exact path="/login" component={Login} />
                    <BookingProvider>
                        <PrivateRoute
                            exact
                            path="/booking/review"
                            component={ReviewBooking}
                        />
                        {/* <Route exact path={`/booking/review`} component={ReviewBooking} /> */}
                        <Route
                            exact
                            path={`/booking/confirm`}
                            component={ConfirmBooking}
                        />
                        <Route exact path="/" component={HomeRouter} />
                        <Route
                            exact
                            path={`/privacy`}
                            component={PrivacyPolicy}
                        />
                        <Route
                            exact
                            path={`/legal`}
                            component={LegalDisclaimer}
                        />
                    </BookingProvider>
                </MainLayout>
            </Switch>
        </BrowserRouter>
    );
}

export default App;
