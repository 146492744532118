import React, { useEffect, useState } from "react";

import UserAdd from "../../../components/User/Add/UserAdd";

const DashboardSkipperAdd = ({ closeWindow }: IDashboardSkipperAddProps) => {
    return <UserAdd closeWindow={closeWindow} role="skipper" />;
};

export default DashboardSkipperAdd;

interface IDashboardSkipperAddProps {
    closeWindow: () => void;
}
