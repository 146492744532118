import React from "react";
import { Form, Input, Row, Col, Button, Alert, Divider } from "antd";

import * as WetwheelsApi from "../../../client-swagger/api";
import { useApp } from "../../../AppContext";

const UserAdd = ({ closeWindow, role }: IUserAddProps) => {
  const { authHeaderOptions, notifyError, notifySuccess } = useApp();

  const switchHandlePost = async (values: any) => {
    switch (role) {
      case "admin":
        return handleNewAdmin(values);

      case "skipper":
        return handleNewSkipper(values);

      case "client":
        return handleNewClient(values);

      case "crew":
        return handleNewCrew(values);
    }
  };

  const handleNewCrew = async (values: any) => {
    var model: WetwheelsApi.CreateCrew = {
      firstName: values.firstName,
      lastName: values.lastName,
      contactNumber: values.contactNumber,
      email: values.email,
    };

    const {
      success,
      message,
      data,
    }: WetwheelsApi.ApiResponse = await new WetwheelsApi.CrewApi().crewCreatePost(
      model,
      authHeaderOptions
    );

    if (!success) {
      notifyError(message);
    } else {
      notifySuccess(`New ${role} added.`);
      closeWindow();
    }
  };

  const handleNewClient = async (values: any) => {
    var model: WetwheelsApi.CreateUser = {
      ...values,
      role,
    };

    const {
      success,
      message,
    }: WetwheelsApi.ApiResponse = await new WetwheelsApi.AccountApi().accountSignup(
      model,
      authHeaderOptions
    );

    if (!success) {
      notifyError(message);
    } else {
      notifySuccess(`New ${role} added.`);
      closeWindow();
    }
  };

  const handleNewSkipper = async (values: any) => {
    let model: WetwheelsApi.CreateSkipper = {
      firstName: values.firstName,
      lastName: values.lastName,
      contactNumber: values.contactNumber,
      email: values.email,
    };

    const {
      success,
      message,
      data,
    }: WetwheelsApi.ApiResponse = await new WetwheelsApi.SkipperApi().skipperCreatePost(
      model,
      authHeaderOptions
    );

    if (!success) {
      notifyError(message);
    } else {
      notifySuccess(`New ${role} added.`);
      closeWindow();
    }
  };

  const handleNewAdmin = async (values: any) => {
    const model: WetwheelsApi.CreateUser = {
      ...values,
      role,
    };

    const {
      success,
      message,
      data,
    }: WetwheelsApi.ApiResponse = await new WetwheelsApi.UserApi().createUser(
      model,
      authHeaderOptions
    );

    if (!success) {
      notifyError(message);
    } else {
      notifySuccess(`New ${role} added.`);
      closeWindow();
    }
  };

  return (
    <>
      <h2>Add new {role}</h2>
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        onFinish={switchHandlePost}>
        <Form.Item label="First name" name="firstName">
          <Input size="large" />
        </Form.Item>
        <Form.Item label="Last name" name="lastName">
          <Input size="large" />
        </Form.Item>
        <Form.Item label="Email" name="email">
          <Input size="large" />
        </Form.Item>
        <Form.Item label="Contact number" name="contactNumber">
          <Input size="large" />
        </Form.Item>

        <Form.Item label="Organisation" name="organisation">
          <Input size="large" />
        </Form.Item>

        <Divider />
        <Row>
          <Col span={24} className="mb-m">
            <Alert
              type="info"
              message="The new user will be sent an email requiring them to set a password and activate their account."></Alert>
          </Col>
        </Row>
        <Form.Item>
          <Button type="primary" size="large" htmlType="submit">
            Add {role}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default UserAdd;

interface IUserAddProps {
  closeWindow: () => void;
  role: string;
}
