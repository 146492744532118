import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useApp } from "../../AppContext";

function PrivateRoute({ component: Component, ...rest }: IPrivateRouteProps) {
  const { redirectTo, jwtToken } = useApp();

  return (
    <Route
      {...rest}
      render={(props) =>
        jwtToken ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: redirectTo },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;

interface IPrivateRouteProps {
  component: any;
  exact?: boolean;
  path: string;
}
