import React from "react";

import { Col, Descriptions, Row, Statistic } from "antd";
import { useApp } from "../../AppContext";
import moment from "moment";

const BookingSummary = () => {
  const { bookingModel, userName } = useApp();

  const timeStart = bookingModel.timeStart
    ? moment(bookingModel.timeStart).format("dddd, MMMM Do YYYY")
    : null;

  const style = { marginBottom: "20px" };

  return bookingModel ? (
    <>

      <Row justify="center">
        <Col span={8}>
          <Statistic title="Activity" style={style} value={bookingModel.activityType}></Statistic>
          <Statistic title="Booking date" style={style} value={timeStart}></Statistic>
          <Statistic title="Start time" style={style} value={moment(bookingModel.timeStart)?.format("LT")}></Statistic>
          {bookingModel.timeEnd && (
            <Statistic title="End time" style={style} value={moment(bookingModel.timeEnd)?.format("LT")}></Statistic>
          )}
          {bookingModel.additionalInformation && (
            <Statistic title="Additional information" style={style} value={bookingModel.additionalInformation}></Statistic>
          )}
        </Col>
        <Col span={8}>

          <Statistic title="Full name" style={style} value={userName}></Statistic>
          <Statistic title="Number of passengers" style={style} value={bookingModel.numberOfPassengers}></Statistic>
          <Statistic title="Number of wheelchair passengers" style={style} value={bookingModel.numberOfWheelChairs}></Statistic>
          <Statistic title="Number of special needs passengers" style={style} value={bookingModel.numberOfNonWheelchairDisabled} ></Statistic>

        </Col>
      </Row>



      {/* <Descriptions
        title="Booking Information"
        layout="vertical"
        bordered
        column={{ xs: 12, sm: 12, md: 24 }}
        className="mt-m mb-m"
      >
        <Descriptions.Item label="Activity" span={12}>
          {bookingModel.activityType}
        </Descriptions.Item>
        <Descriptions.Item label="Full name" span={12}>
          {userName}
        </Descriptions.Item>
        <Descriptions.Item label="Booking date" span={12}>
          {timeStart}
        </Descriptions.Item>
        <Descriptions.Item label="Start time" span={12}>
          {moment(bookingModel.timeStart)?.format("LT")}
        </Descriptions.Item>
        {bookingModel.timeEnd && (
          <Descriptions.Item label="End time" span={12}>
            {moment(bookingModel.timeEnd)?.format("LT")}
          </Descriptions.Item>
        )}

        <Descriptions.Item label="Number of passengers" span={12}>
          {bookingModel.numberOfPassengers}
        </Descriptions.Item>
        <Descriptions.Item label="Number of wheelchair passengers" span={12}>
          {bookingModel.numberOfWheelChairs}
        </Descriptions.Item>
        <Descriptions.Item label="Number of special needs passengers" span={12}>
          {bookingModel.numberOfNonWheelchairDisabled}
        </Descriptions.Item>

        {bookingModel.additionalInformation && (
          <Descriptions.Item label="Additional information" span={24}>
            {bookingModel.additionalInformation}
          </Descriptions.Item>
        )}
      </Descriptions> */}
    </>
  ) : (
    <p>No booking?</p>
  );
};

export default BookingSummary;
