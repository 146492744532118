import React, { useMemo, useState } from "react";

const BookingContext = React.createContext<IBookingContext | undefined>(
  undefined
);

function BookingProvider({ children }: IBookingProviderProps) {
  const [step, setStep] = useState<number>(0);
  const [bookingId, setBookingId] = useState<string>("");
  const [uniqueRef, setUniqueRef] = useState<string>("");

  const state = useMemo<IBookingContext>(
    () => ({
      step,
      setStep,
      bookingId,
      setBookingId,
      uniqueRef,
      setUniqueRef
    }),
    [step]
  );

  return (
    <BookingContext.Provider value={state}>{children}</BookingContext.Provider>
  );
}

function useBooking() {
  const context = React.useContext(BookingContext);

  if (context === undefined) {
    throw new Error("useBooking must be used within a BookingProvider");
  }

  return context;
}

export { BookingProvider, useBooking };

interface IBookingProviderProps {
  children: React.ReactNode;
}

interface IBookingContext {
  step: number;
  setStep: (value: number) => void;
  bookingId: string;
  setBookingId: (value: string) => void;
  uniqueRef: string;
  setUniqueRef:  (value: string) => void;
}
