import React, { useState } from "react";
import {
    Form,
    Input,
    Space,
    Table,
    Progress,
    notification,
    Button,
    Col,
    Row,
    Divider,
    Alert,
    message,
} from "antd";
import IUser from "../../../interfaces/IClients";
import { Link } from "react-router-dom";
import * as WetwheelsApi from "../../../client-swagger/api";
import { useApp } from "../../../AppContext";
import CardRow from "../../Card/CardRow";
import moment from "moment";

const UserDetail = ({
    user,
    closeWindow,
    message,
    bindData,
}: IUserDetailProps) => {
    const { notifyError, notifySuccess, authHeaderOptions } = useApp();
    const handleDetailSubmit = async (values: any) => {
        // edit the skipper values
        if (user) {
            // make model
            var model: WetwheelsApi.CreateUser = {
                ...values,
            };

            // send it
            const { success, message, data }: WetwheelsApi.ApiResponse =
                await new WetwheelsApi.UserApi().updateUser(
                    model,
                    user.id,
                    authHeaderOptions,
                );

            if (!success) {
                notifyError(message);
            } else {
                notifySuccess(message);
                closeWindow();
            }
        }
    };

    const handleSendPasswordReset = async () => {
        const { success, message, data }: WetwheelsApi.ApiResponse =
            await new WetwheelsApi.AccountApi().requestResetPassword(
                {
                    email: user.email,
                },
                authHeaderOptions,
            );

        if (!success) {
            notifyError(message);
        } else {
            notifySuccess(
                `A password reset email has been sent to ${user.email}`,
            );
        }
    };

    const handleDisableClick = async (
        e: React.MouseEvent<HTMLElement, MouseEvent>,
        user: any,
    ) => {
        e.stopPropagation();
        const { success, message } =
            await new WetwheelsApi.UserApi().disableUser(
                user.id,
                authHeaderOptions,
            );
        if (!success) {
            notifyError(message);
            return;
        }
        notifySuccess("User status toggled");
        typeof bindData == "function" && (await bindData());
    };

    return (
        <>
            <h2>Edit details</h2>
            {message && (
                <Alert className="mb-m" type="info" message={message} />
            )}
            {user && (
                <Form
                    labelCol={{ span: 24 }}
                    layout="vertical"
                    onFinish={handleDetailSubmit}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label="First name"
                                name="firstName"
                                initialValue={user.firstName}
                            >
                                <Input size="large" />
                            </Form.Item>
                            <Form.Item
                                label="Last name"
                                name="lastName"
                                initialValue={user.lastName}
                            >
                                <Input size="large" />
                            </Form.Item>
                            <Form.Item
                                label="Email"
                                name="email"
                                initialValue={user.email}
                            >
                                <Input size="large" />
                            </Form.Item>
                            <Form.Item
                                label="Contact number"
                                name="contactNumber"
                                initialValue={user.contactNumber}
                            >
                                <Input size="large" />
                            </Form.Item>

                            <Form.Item
                                label="Organisation"
                                name="organisation"
                                initialValue={user.organisationName}
                            >
                                <Input size="large" />
                            </Form.Item>

                            <Divider />
                            <Row gutter={16}>
                                {user.isLockedOut ? (
                                    <Col span={24}>
                                        <Alert
                                            message="This user is locked out."
                                            description="To unlock immediately, set their password. Otherwise. send them a password reset email and they will be unlocked when they change their password. "
                                            type="warning"
                                        ></Alert>
                                    </Col>
                                ) : null}
                                {user.isDisabled ? (
                                    <Col span={24}>
                                        <Alert
                                            message="This user is disabled."
                                            description="They will have no access to the system. You can enable them below."
                                            type="error"
                                        ></Alert>
                                    </Col>
                                ) : null}

                                <Col>
                                    <Button
                                        size="large"
                                        className="my-s"
                                        // className="a-button v-button-warning"
                                        onClick={(e) =>
                                            handleDisableClick(e, user)
                                        }
                                    >
                                        {user.isDisabled
                                            ? "Enable user"
                                            : "Disable user"}
                                    </Button>
                                </Col>

                                <Col span={24}>
                                    <p className="mb-s mt-s">
                                        If you wish to change the User's
                                        password, please enter a new password
                                        twice below and save. They will not be
                                        sent any emails.
                                    </p>
                                </Col>
                            </Row>
                            <Row gutter={16} className="mt-m " justify="center">
                                <Form.Item
                                    className="mx-m w-50"
                                    name="password"
                                    label="Password"
                                >
                                    <Input type="password" />
                                </Form.Item>

                                <Form.Item
                                    className="mx-m w-50"
                                    name="confirmPassword"
                                    label="Confirm password"
                                >
                                    <Input type="password" />
                                </Form.Item>
                            </Row>
                        </Col>
                    </Row>

                    <Divider />
                    <Form.Item>
                        <Row
                            className="m-small-screen-stack"
                            justify="center"
                            align="middle"
                        >
                            <Button
                                className="m-xs"
                                type="primary"
                                size="large"
                                htmlType="submit"
                            >
                                Save details
                            </Button>

                            <Button
                                type="default"
                                size="large"
                                htmlType="button"
                                className="m-xs"
                                onClick={handleSendPasswordReset}
                            >
                                Send password reset email
                            </Button>
                        </Row>
                    </Form.Item>
                    <Divider />

                    <div className="mb-l">
                        <h4>Other information</h4>
                        <CardRow
                            label="Created"
                            value={moment(user.createdDate).format(
                                "MMMM Do YYYY, h:mm:ss a",
                            )}
                        />
                        <CardRow
                            label="Updated"
                            value={moment(user.updatedDate).format(
                                "MMMM Do YYYY, h:mm:ss a",
                            )}
                        />
                        <CardRow
                            label="Last locked out"
                            value={
                                !user.lastLockoutDate
                                    ? "Never"
                                    : moment(user.lastLockoutDate).format(
                                          "MMMM Do YYYY, h:mm:ss a",
                                      )
                            }
                        />
                    </div>
                </Form>
            )}
        </>
    );
};

export default UserDetail;

interface IUserDetailProps {
    user: any;
    closeWindow: () => void;
    message?: string;
    bindData?: () => void;
}
