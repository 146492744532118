import { Alert, Button, Card, Col, Descriptions, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import User from "../../../../enums/User";

const BookingDetails = ({ bookingData }: any) => {


    return bookingData && (
        <Card type={"inner"}>
            <h4>Booking details</h4>
            <Descriptions>
                <Descriptions.Item span={24} label="Date of trip">
                    {moment(bookingData.startDatetime).format(
                        "LL"
                    )}
                </Descriptions.Item>
                <Descriptions.Item span={24} label="Activity">
                    {bookingData.eventType.name}
                </Descriptions.Item>
                <Descriptions.Item span={24} label="Start time">
                    {moment(bookingData.startDatetime).format("HH:mm")}
                </Descriptions.Item>
                <Descriptions.Item span={24} label="End time">
                    {moment(bookingData.endDatetime).format("HH:mm")}
                </Descriptions.Item>
                <Descriptions.Item span={24} label="Number of passengers">
                    {bookingData.numberOfPassengers}
                </Descriptions.Item>
                <Descriptions.Item span={24} label="Number of wheelchairs">
                    {bookingData.numberOfWheelchairs}
                </Descriptions.Item>


                {!bookingData.additionalInformation ?
                    <Descriptions.Item labelStyle={{ breakAfter: "always" }} span={24} label="Additional information">
                        None
                    </Descriptions.Item> : null
                }
            </Descriptions>
            {bookingData.additionalInformation ? <>
                <label>Additional information:</label><br /><br />{bookingData.additionalInformation}
            </> : null}






        </Card>
    )
}

export default BookingDetails;
