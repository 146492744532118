import React from "react";
import NavMenu from "./NavMenu";
import Footer from "./Footer";
import { Layout } from "antd";
import { useApp } from "../AppContext";
const { Content } = Layout;

function MainLayout({ children }: IMainLayout) {
  const { isAuthenticated } = useApp();

  window.addEventListener("click", () => {
    isAuthenticated();
  });

  return (
    // <Redirect to="/login" />
    // ) : (
    <>
      <NavMenu />
      <Content className="o-content">{children}</Content>
      <Footer />
    </>
  );
}

export default MainLayout;

interface IMainLayout {
  children: React.ReactNode;
}
