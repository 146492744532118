import React, { useEffect, useState } from "react";
import { Row } from "antd";
import Time from "./Time";
import * as WetwheelsApi from "../../client-swagger/api";
import { useCalendar } from "./CalendarContext";

function SlotSelect() {
    const { daySelected, slots, setSlots } = useCalendar();

    useEffect(() => {
        new WetwheelsApi.BookingApi()
            .bookingListAvailableTimesByDayGet(daySelected.local().format("YYYY-MM-DD"))
            .then(({ value }: any) => {
                setSlots(value.data);
            });
    }, []);

    return slots ? (
        <>
            <Row gutter={[16, 16]} justify="space-between" className="mb-l">
                <h3>3 of 4 - Select time</h3>
            </Row>
            <Row gutter={[16, 16]} justify="center" className="times mb-l">
                {slots && (
                    <>
                        {slots.map((slot, index) => {
                            return (
                                <Time
                                    key={index}
                                    available={slot.available}
                                    start={slot.startTime}
                                    end={slot.endTime}
                                    index={index}
                                />
                            );
                        })}
                    </>
                )}
            </Row>
        </>
    ) : null;
}

export default SlotSelect;
