import React, { useState, useEffect } from "react";
import { Row, Col, Result, Button } from "antd";
import { Link, useParams } from "react-router-dom";
import { AccountApi } from "../../client-swagger/api";
import { useApp } from "../../AppContext";

function ConfirmEmail() {
    const { setJwtToken, bookingModel, setBookingModel } = useApp();
    const { userId } = useParams<EmailParams>();
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const getResult = async () => {
            const activate = await new AccountApi().activateUser({
                userId: String(userId),
            });
            setLoading(false);
            if (!activate.success) {
                setErrorMessage(activate.message);
                return;
            }
            setSubmitted(true);
            setJwtToken(activate.data);
        };

        getResult();
    }, []);

    const handleCancelBooking = () => {
        setBookingModel(null);
    };

    return loading ? (
        <Row className="mt-l mb-l">
            <Col span={12} offset={6}>
                <p>Confirming your account...</p>
            </Col>
        </Row>
    ) : submitted ? (
        <>
            <Row className="mt-l mb-l">
                <Col span={12} offset={6}>
                    <Result
                        status="success"
                        title="Thank you for confirming your email address"
                        subTitle="You are now logged in"
                        extra={
                            bookingModel ? (
                                <>
                                    <Row>
                                        <Col
                                            span={4}
                                            offset={10}
                                            className="mt-m center-xs"
                                        >
                                            <Button
                                                htmlType="button"
                                                type="primary"
                                                size="large"
                                            >
                                                <Link to="/booking/review">
                                                    Proceed with booking {">"}
                                                </Link>
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col
                                            span={4}
                                            offset={10}
                                            className="mt-m center-xs"
                                        >
                                            <Button
                                                size="middle"
                                                type="default"
                                            >
                                                <Link
                                                    onClick={
                                                        handleCancelBooking
                                                    }
                                                    to="/"
                                                >
                                                    Cancel and start again
                                                </Link>
                                            </Button>
                                        </Col>
                                    </Row>
                                </>
                            ) : (
                                <>
                                    <Row>
                                        <Col
                                            span={4}
                                            offset={10}
                                            className="mt-m center-xs"
                                        >
                                            <Button
                                                htmlType="button"
                                                type="primary"
                                                size="large"
                                            >
                                                <Link to="/">
                                                    Start a booking {">"}
                                                </Link>
                                            </Button>
                                        </Col>
                                    </Row>
                                </>
                            )
                        }
                    />
                </Col>
            </Row>
        </>
    ) : (
        <Row className="mt-l mb-l">
            <Col span={12} offset={6}>
                <Result
                    status="error"
                    title="Your account could not be confirmed."
                    subTitle={errorMessage}
                />
            </Col>
        </Row>
    );
}

export default ConfirmEmail;

type EmailParams = {
    userId: string;
};
