import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useApp } from "../../AppContext";
import User from "../../enums/User";
import ManifestPrint from "./Booking/Common/ManifestPrint";
import decodeJwt from "jwt-decode";
import IJwtToken from "../../interfaces/IJwtToken";
import PrivateRoute from "../../components/Util/PrivateRoute";

function ManifestPrintRoute({ match }: RouteComponentProps) {
    const { userType, setUserType, jwtToken } = useApp();

    useEffect(() => {
        if (!jwtToken) {
            return;
        }

        async function checkToken(jwtToken: string): Promise<IJwtToken> {
            const decodedToken: IJwtToken = await decodeJwt(jwtToken);
            return decodedToken;
        }

        if (jwtToken) {
            checkToken(jwtToken).then((token) => {
                const role = token.role;
                switch (role) {
                    case "client":
                        setUserType(User.Client);
                        break;
                    case "skipper":
                        setUserType(User.Skipper);
                        break;
                    case "admin":
                        setUserType(User.Admin);
                        break;
                    default:
                        setUserType(User.None);
                        break;
                }
            });
        }
    }, []);

    return (
        <>
            {userType !== User.None && (
                <PrivateRoute
                    exact
                    path={`${match.path}/booking/:id`}
                    component={ManifestPrint}
                />
            )}
        </>
    );
}

export default ManifestPrintRoute;
