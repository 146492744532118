import React, { useState, useEffect } from "react";
import UserDetail from "../../../components/User/Detail/UserDetail";
import BookingList from "../../../components/Booking/BookingList";
import { useApp } from "../../../AppContext";
import * as WetwheelsApi from "../../../client-swagger/api";

const DashboardCrewDetail = ({
    user,
    closeWindow,
}: IDashboardCrewDetailProps) => {
    const { authHeaderOptions, notifyError } = useApp();

    const [bookings, setBookings] = useState<any[]>(null);

    const fetchBookings = async () => {
        if (!user || !user.id) {
            return;
        }
        const { success, data, message } =
            await new WetwheelsApi.BookingApi().bookingsByUser(
                user.id,
                authHeaderOptions,
            );

        if (!success) {
            notifyError(message);
        }

        setBookings(data);
    };

    useEffect(() => {
        const getData = async () => {
            await fetchBookings();
        };

        getData();
    }, [user]);

    return (
        <>
            <UserDetail closeWindow={closeWindow} user={user} />
            {/* <h3>Bookings</h3>
      <BookingList bookings={bookings} /> */}
        </>
    );
};

export default DashboardCrewDetail;

interface IDashboardCrewDetailProps {
    user: any;
    closeWindow: () => void;
}
