import React from "react";
import { Row, Col } from "antd";

interface IDetailsCollapsePanelPassengerInfo {
  label: string;
  value: any;
}

const DetailsCollapsePanelPassengerInfo = ({
  label,
  value,
}: IDetailsCollapsePanelPassengerInfo) => {
  return (
    <div>
      <Row className="mt-xxs">
        <Col className="my-xxs" xs={24} md={12}>
          <p>{label}</p>
        </Col>
        <Col className="my-xxs" xs={24} md={12}>
          {value ?? "value not read"}
        </Col>
      </Row>
    </div>
  );
};

export default DetailsCollapsePanelPassengerInfo;
