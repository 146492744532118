import { Col, Row } from "antd";
import React from "react";

const LegalDisclaimer = () => {
  return (
    <Row style={{ marginTop: "-8rem" }} className="o-legal-content">
      <Col
        xs={{ span: 22, offset: 1 }}
        // style={{
        //   color: "black",
        //   width: "90vw",
        //   display: "flex",
        //   flexDirection: "column",
        //   alignItems: "center",
        // }}
      >
        <h3>Terms &amp; Conditions</h3>
        <div data-component-prefix="paragraph">
          <p>
            <u>
              <strong>Wetwheels’ Website Terms and Conditions</strong>
            </u>
          </p>

          <p>
            Welcome to our website. If you continue to browse and use this
            website, you are agreeing to comply with and be bound by the
            following terms and conditions of use, which together with our
            Privacy and Cookie Policy govern Wetwheels’ relationship with you in
            relation to this website.
          </p>

          <p>
            Please read these terms and conditions carefully, as they affect
            your legal rights. Your agreement to comply with and be bound by
            these terms and conditions is deemed to occur upon your first use of
            the website. If you do not agree to be bound by these terms and
            conditions, you should stop using the website immediately.
          </p>

          <p>
            Wetwheels refers to the Wetwheels Foundation charity, and our
            associated companies, Wetwheels Hamble CIC, Wetwheels Jersey,
            Wetwheels Solent CIC, Wetwheels South East CIC and Wetwheels
            Yorkshire CIC. The Wetwheels Foundation is charity number 1148600
            registered with the Charity Commission of England and Wales, at The
            Victory, Gunwharf Quays House, Portsmouth, PO1 3TZ. The term
            Wetwheels or ‘us’ or ‘we’ refers to the owner of the website. The
            term ‘you’ refers to the user or viewer of our website.
          </p>

          <p>
            The use of this website is subject to the following terms of use:
          </p>

          <ol>
            <li>
              <p>
                The content of the pages of this website is for your general
                information and use only. It is subject to change without
                notice. Content means any text, graphics, images, audio, video,
                data compilations, page layout, underlying code and software and
                any other form of information capable of being stored in a
                computer that appears on or forms part of this website. Such
                content is protected by copyright, trademarks, database rights
                and other intellectual property rights.
              </p>
            </li>
            <li>
              <p>
                This website uses cookies to monitor browsing preferences, see
                our Privacy and Cookie Policy.
              </p>
            </li>
            <li>
              <p>
                While we endeavour to keep the information on our website up to
                date and correct, neither we nor any third parties provide any
                warranty or guarantee as to the accuracy, reliability,
                timeliness, performance, completeness or suitability of the
                information and materials found or offered on our website for
                any particular purpose. You acknowledge that such information
                and materials contain inaccuracies or errors and we expressly
                exclude liability for any such inaccuracies or errors and any
                reliance you place on such information is therefore strictly at
                your own risk.
              </p>
            </li>
            <li>
              <p>
                It shall be your own responsibility to ensure that any products,
                services or information available through this website meet your
                specific requirements.
              </p>
            </li>
            <li>
              <p>
                This website and its content is copyright of Wetwheels
                containing content which is owned or licensed to us. You must
                not reproduce, modify, copy, distribute or use any content for
                commercial purposes or transmit or store it to any other website
                without prior written permission from Wetwheels. This applies to
                both the visual content of the website and the source code.
              </p>
            </li>
            <li>
              <p>
                All trademarks and images reproduced in this website that are
                not the property, or licensed to Wetwheels, remain the property
                and trademark of the owner and should not be copied or
                reproduced without their permission.
              </p>
            </li>
            <li>
              <p>
                Whilst we use reasonable endeavours to ensure that the website
                is secure and free of errors, viruses and other malware we give
                no warranty or guarantee in that regard and all users take
                responsibility for their own security, that of their personal
                data and their computers. We will not be liable for any loss or
                damage caused.
              </p>
            </li>
            <li>
              <p>
                Any malicious activity or unauthorised use of this website may
                give rise to a claim for damages and/or be a criminal offence.
                In the event of such a breach your right to use the website will
                cease immediately.
              </p>
            </li>
            <li>
              <p>
                From time to time this website may also include links to other
                websites. These links are provided for convenience only to
                provide further information. We have no control over the nature,
                content and availability of those sites. The inclusion of links
                does not imply a recommendation or endorse the views expressed
                within them. We have no control over such linked websites or the
                content of them and cannot accept any responsibility for them.
              </p>
            </li>
            <li>
              <p>
                Every effort is made to keep the website up and running
                smoothly. However, Wetwheels takes no responsibility, and will
                not be liable for, any disruption or non-availability of the
                website. Access to the website may be suspended temporarily and
                without notice in the case of system failure, maintenance or
                repair, for reasons beyond our control or any other reason we
                consider appropriate in the circumstances. We shall have no
                liability in respect of any such suspension.
              </p>
            </li>
            <li>
              <p>
                Wetwheels reserve the right to alter, suspend or discontinue any
                part (or the whole of) the website including, but not limited
                to, any products and/or services available. These terms and
                conditions will continue to apply to any modified version of the
                website unless it is expressly stated otherwise.
              </p>
            </li>
            <li>
              <p>
                In no event will Wetwheels be liable for any loss or damage
                including without limitation, indirect or consequential loss or
                damage, or any loss or damage whatsoever arising from loss of
                data or profits arising out of, or in connection with, the use
                of this website.
              </p>
            </li>
            <li>
              <p>
                These terms and conditions may be amended by us from time to
                time. Such revised terms will apply to the website from the date
                of publication. You should check the terms and conditions
                regularly to ensure familiarity with the current version.
              </p>
            </li>
            <li>
              <p>
                Your use of this website and any dispute arising out of such use
                of the website is governed by and construed with the laws of
                England, Northern Ireland, Scotland and Wales and subject to the
                jurisdiction of the English courts.
              </p>
            </li>
          </ol>

          <p>
            <strong>Contact details</strong>
          </p>

          <p>
            If you would like to contact us about any aspect of our website
            please email us at
            <a href="mailto:enquiries@wetwheelsfoundation.org ">
              <strong> enquiries@wetwheelsfoundation.org </strong>
            </a>
          </p>
        </div>
      </Col>
    </Row>
  );
};

export default LegalDisclaimer;
