import {
    Layout,
    Row,
    Col,
    Form,
    Input,
    Button,
    notification,
    Alert,
    Card,
    Drawer,
    Divider,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useApp } from "../../../../AppContext";
import * as WetwheelsApi from "../../../../client-swagger/api";
import CombinedBookingSummary from "./CombinedBookingSummary";
import ManifestPassengerCard from "./ManifestPassengerCard";

const ReadOnlyManifest = ({ booking }: any) => {
    const totalPassengers = booking.numberOfPassengers;
    const manifestPassengers =
        booking && booking.manifest ? booking.manifest.passengers.length : 0;
    const disclaimerSignedBy = booking.disclaimerSignedBy;

    const viewManifestButton = (
        <Link to={`/dashboard/bookings/${booking.id}/manifest`}>
            <Button size="middle" type="default">
                Edit Manifest
            </Button>
        </Link>
    );

    return (
        <Row justify="space-between" className="mb-m" gutter={16}>
            <Col span={24}>
                <h3>
                    <a href={`/dashboard/bookings/${booking.id}`}>
                        {booking.uniqueKey} - view booking
                    </a>
                </h3>
                <ManifestPassengerCard
                    bookingData={booking}
                ></ManifestPassengerCard>

                {totalPassengers > manifestPassengers ? (
                    <Alert
                        type="error"
                        message={`Booking has ${totalPassengers} passenger(s) but manifest has ${manifestPassengers} passenger(s)`}
                        action={viewManifestButton}
                    ></Alert>
                ) : null}
                {totalPassengers === manifestPassengers &&
                disclaimerSignedBy ? (
                    <Alert
                        type="success"
                        message={`Manifest is complete and disclaimer signed by ${disclaimerSignedBy}`}
                        action={viewManifestButton}
                    ></Alert>
                ) : null}
                {totalPassengers === manifestPassengers &&
                !disclaimerSignedBy ? (
                    <Alert
                        type="warning"
                        message={`Manifest is complete but disclaimer has not been signed`}
                        action={viewManifestButton}
                    ></Alert>
                ) : null}

                <Divider></Divider>
            </Col>
        </Row>
    );
};

export default ReadOnlyManifest;
