import React from "react";
import { Alert, Row, Col } from "antd";
import { useApp } from "../../../../AppContext";
import User from "../../../../enums/User";
import ManifestPassengerCard from "./ManifestPassengerCard";
import ManifestAlert from "./ManifestAlert";
import Disclaimer from "./Disclaimer";

export interface IManifestPassengerListProps {
    bookingData: any;
}

const ManifestPassengerList = ({
    bookingData,
}: IManifestPassengerListProps) => {
    const { id } = bookingData;
    const { userType, userId } = useApp();
    const isAdminOrSkipperOrCrew =
        userType === User.Skipper ||
        userType === User.Admin ||
        userType === User.Crew;

    let showButton = false;
    switch (userType) {
        case User.Admin:
        case User.Client:
            showButton = true;
            break;
        case User.Skipper:
            showButton =
                bookingData.skipper && bookingData.skipper.userId === userId;
            break;
        case User.Crew:
            showButton = bookingData.crew && bookingData.crew.userId === userId;
            break;
    }

    return bookingData.manifest ? (
        <>
            <Row className="mb-m">
                <Col span={24}>
                    <h4>Manifest</h4>
                </Col>
            </Row>

            {bookingData.manifest.passengers ? (
                <>
                    <Row className="mb-m mt-m" gutter={16}>
                        <Col span={24}>
                            <ManifestPassengerCard bookingData={bookingData} />
                        </Col>
                        <Col span={24}>
                            {bookingData.manifest.passengers.length <
                            bookingData.numberOfPassengers ? (
                                <Alert
                                    style={{ width: "100%" }}
                                    message={`The booking specifies ${bookingData.numberOfPassengers} passengers but the manifest only contains details for ${bookingData.manifest.passengers.length} passenger(s)`}
                                    type="warning"
                                ></Alert>
                            ) : bookingData.manifest.passengers.length ===
                              bookingData.numberOfPassengers ? (
                                <Alert
                                    style={{ width: "100%" }}
                                    message="All passenger details have been completed"
                                    type="success"
                                ></Alert>
                            ) : (
                                <Alert
                                    style={{ width: "100%" }}
                                    message={`The booking specifies ${
                                        bookingData.numberOfPassengers
                                    } passengers but the manifest contains details for ${
                                        bookingData.manifest.passengers.length
                                    } passenger(s). Please remove  ${
                                        bookingData.manifest.passengers.length -
                                        bookingData.numberOfPassengers
                                    } passenger(s)`}
                                    type="warning"
                                ></Alert>
                            )}
                        </Col>
                        <Col span={24}>
                            <ManifestAlert
                                message="Please ensure that the manifest is accurate before departure"
                                button="Amend now"
                                id={id}
                                type="info"
                                page="manifest"
                                showButton={showButton}
                            />
                        </Col>
                    </Row>
                </>
            ) : (
                <ManifestAlert
                    message="The manifest must be completed before departure"
                    button="Complete now"
                    id={id}
                    type="error"
                    page="manifest"
                    showButton={showButton}
                />
            )}

            <Row className="mb-m mt-m" gutter={16}>
                <Col span={24}>
                    <h4>Disclaimer</h4>
                </Col>
            </Row>

            {bookingData.disclaimerSignedDate ? (
                <Disclaimer bookingData={bookingData} />
            ) : (
                <ManifestAlert
                    message="Disclaimer must be completed before departure:"
                    button="Complete now"
                    id={id}
                    type="error"
                    page="disclaimer"
                    showButton={showButton}
                />
            )}
        </>
    ) : (
        <Row
            className="mb-m"
            justify="space-between"
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        >
            <Col span={24}>
                <h4>Manifest</h4>
            </Col>
            <ManifestAlert
                message={
                    isAdminOrSkipperOrCrew
                        ? "The client has not yet completed their manifest"
                        : "You have not completed your manifest"
                }
                id={id}
                button="Complete now"
                type="info"
                page="manifest"
                showButton={showButton}
            />

            <Col span={24}>
                <h4>Disclaimer</h4>
                <p>
                    The disclaimer has not been signed. The manifest must be
                    completed first.
                </p>
            </Col>
        </Row>
    );
};

export default ManifestPassengerList;
