import React, { useState, useEffect } from "react";
import { Layout, Drawer, Row, Col } from "antd";
import DashboardClientDetail from "./DashboardClientDetail";
import DashboardClientAdd from "./DashboardClientsAdd";
import { useParams, useHistory } from "react-router-dom";
import * as WetwheelsApi from "../../../client-swagger/api";
import { useApp } from "../../../AppContext";
import UserList from "../../../components/User/List/UserList";

function DashboardClients() {
    const { authHeaderOptions, notifyError } = useApp();
    const history = useHistory();

    const [isClientListLoading, setIsClientListLoading] =
        useState<boolean>(true);
    const [clientList, setClientList] = useState();

    const [data, setData] = useState(null);
    const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
    const [drawerAddVisible, setDrawerAddVisible] = useState<boolean>(false);

    const { id } = useParams() as any;

    let disable = false;

    const onClose = () => {
        setData(null);
        setDrawerVisible(false);
        setDrawerAddVisible(false);
        fetchList();
    };

    const handleNewClientClick = () => {
        setDrawerVisible(false);
        setDrawerAddVisible(true);
    };

    const handleRow = (record: any) => {
        return {
            onClick: () => {
                if (disable) return;
                if (record) {
                    setData(record);
                }
                setDrawerAddVisible(false);
                setDrawerVisible(true);
            },
        };
    };

    const fetchList = async () => {
        setIsClientListLoading(true);
        const { data, success, message } =
            await new WetwheelsApi.UserApi().clients(authHeaderOptions);

        if (!success) {
            notifyError(message);
            return;
        }

        setClientList(data);
        setIsClientListLoading(false);
    };

    const fetchClient = async (id: string) => {
        const { data, success } = await new WetwheelsApi.UserApi().user(
            id,
            authHeaderOptions,
        );
        if (!success) {
            notifyError("Unable to load client");
            return;
        }

        if (data) {
            setData(data);
            setDrawerAddVisible(false);
            setDrawerVisible(true);
        }
    };

    useEffect(() => {
        fetchList();

        const loadClient = async (id: string) => {
            await fetchClient(id);
        };
        if (id) {
            loadClient(id);
        }
    }, []);

    return (
        <>
            <Layout>
                <Layout.Header
                    className="mb-m v-ant-layout-header"
                    style={{ padding: 0, background: "none", width: "100%" }}
                >
                    <Row justify="space-between" align="middle">
                        <Col>
                            <h2 className="mr-m">Clients</h2>
                        </Col>
                        <Col>
                            <button
                                className="a-button v-button-primary"
                                onClick={handleNewClientClick}
                            >
                                Add new client
                            </button>
                        </Col>
                    </Row>
                </Layout.Header>
                <Layout.Content>
                    <UserList
                        bindData={fetchList}
                        handleRowClick={handleRow}
                        isLoading={isClientListLoading}
                        list={clientList}
                    ></UserList>
                </Layout.Content>
            </Layout>
            <Drawer
                width={"75vw"}
                onClose={onClose}
                visible={drawerVisible}
                bodyStyle={{ paddingBottom: 80 }}
            >
                <DashboardClientDetail user={data} closeWindow={onClose} />
            </Drawer>

            <Drawer
                width={"75vw"}
                onClose={onClose}
                visible={drawerAddVisible}
                bodyStyle={{ paddingBottom: 80 }}
            >
                <DashboardClientAdd closeWindow={onClose} />
            </Drawer>
        </>
    );
}

export default DashboardClients;
