import React, { useEffect, useState } from "react";
import { Form, Input, Button } from "antd";
import IEventType from "../../../interfaces/IEventType";

import * as WetwheelsApi from "../../../client-swagger/api";
import { useApp } from "../../../AppContext";

function DashboardEventTypeDetail({
  eventTypeId,
  onClose,
}: IDashboardEventTypeDetailProps) {
  const { authHeaderOptions, notifyError, notifySuccess } = useApp();
  const [eventData, setEventData] = useState<IEventType>();

  const [form] = Form.useForm();

  const saveExisting = async (values: any) => {
    var tempEvent: WetwheelsApi.EventType = {
      id: eventTypeId ?? null,
      name: values.name ?? null,
      description: values.description ?? null,
      noOfSlots: values.noOfSlotsRequired ?? null,
    };
    // edit an existing
    const {
      success,
      message,
    } = await new WetwheelsApi.EventApi().updateEventType(
      tempEvent,
      authHeaderOptions
    );

    if (!success) {
      notifyError(message);
      return;
    }

    notifySuccess(message);
    onClose();
  };

  const saveNew = async (values: any) => {
    var tempEvent: WetwheelsApi.EventType = {
      name: values.name ?? null,
      description: values.description ?? null,
      noOfSlots: values.noOfSlotsRequired ?? 1,
    };

    // make a new eventType
    const {
      success,
      message,
    } = await new WetwheelsApi.EventApi().createEventType(
      tempEvent,
      authHeaderOptions
    );

    if (!success) {
      notifyError(message);
      return;
    }

    notifySuccess(message);
    onClose();
  };

  const handleSaveClick = async (values: any) => {
    if (eventTypeId) {
      await saveExisting(values);
    } else {
      await saveNew(values);
    }
    onClose();
  };

  const fetchEventData = async () => {
    if (eventTypeId === null) {
      setEventData({
        id: "",
        name: "",
        description: "",
        noOfSlotsRequired: 1,
      });
      return;
    }

    const {
      data,
      success,
      message,
    } = await new WetwheelsApi.EventApi().getEventType(eventTypeId);

    if (!success) {
      notifyError(message);
    }

    setEventData(data);
  };

  useEffect(() => {
    // fetch the data
    fetchEventData();
  }, []);

  return eventData ? (
    <>
      <h2>Event</h2>
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        form={form}
        onFinish={handleSaveClick}>
        <Form.Item
          name="name"
          label="Event name"
          initialValue={eventData.name}
          rules={[
            {
              required: true,
              message: "Please enter event name",
            },
          ]}>
          <Input size="large" placeholder="Event Name" />
        </Form.Item>
        <Form.Item
          name="description"
          label="Event description"
          initialValue={eventData.description}
          rules={[
            {
              required: true,
              message: "Please enter event description",
            },
          ]}>
          <Input size="large" placeholder="Event Description" />
        </Form.Item>
        <Form.Item
          name="noOfSlotsRequired"
          label="Number of slots"
          initialValue={eventData.noOfSlotsRequired}
          rules={[
            {
              required: true,
              message: "Please choose number of event slots",
            },
          ]}>
          <Input size="large" placeholder="Number of slots" type="number" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save event type
          </Button>
        </Form.Item>
      </Form>
    </>
  ) : null;
}

export default DashboardEventTypeDetail;

interface IDashboardEventTypeDetailProps {
  eventTypeId: string;
  onClose: () => void;
}
