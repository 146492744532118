import React from "react";
import { Table, Button, Badge } from "antd";

import * as WetwheelsApi from "../../../client-swagger/api";
import { Breakpoint } from "antd/lib/_util/responsiveObserve";
import { useApp } from "../../../AppContext";

const UserList = ({
  list,
  handleRowClick,
  isLoading,
  additionalColumns,
  bindData,
}: IUserListProps) => {
  //const [data, setData] = useState(list);

  const { authHeaderOptions, notifySuccess, notifyError } = useApp();

  if (!additionalColumns) {
    additionalColumns = [];
  }

  const handleDisableClick = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    record: any
  ) => {
    e.stopPropagation();
    const { success, message } = await new WetwheelsApi.UserApi().disableUser(
      record.id,
      authHeaderOptions
    );
    if (!success) {
      notifyError(message);
      return;
    }
    notifySuccess("User status toggled");
    await bindData();
  };

  const columns = [
    {
      title: "",
      dataIndex: "badge",
      key: "badge",
      render: (value: any, record: any) => {
        const status = record.isDisabled
          ? "error"
          : record.isLockedOut
          ? "warning"
          : "success";
        return <Badge className="a-badge-l" status={status}></Badge>;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: string, record: any) => {
        return record.firstName + " " + record.lastName;
      },
      sorter: (a: any, b: any) => {
        return a.firstName.localeCompare(b.firstName) as number;
      },
    },

    {
      title: "Organisation",
      dataIndex: "organisationName",
      key: "organisationName",
      responsive: ["xl"] as Breakpoint[],
      sorter: (a: any, b: any) => {
        return a.organisationName.localeCompare(b.organisationName) as number;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      responsive: ["md"] as Breakpoint[],
      key: "email",
      sorter: (a: any, b: any) => {
        return a.email.localeCompare(b.email) as number;
      },
    },
    {
      title: "Contact number",
      dataIndex: "contactNumber",
      key: "contactNumber",
      sorter: (a: any, b: any) => {
        return a.contactNumber.localeCompare(b.contactNumber) as number;
      },
    },
    {
      title: "Locked out?",
      dataIndex: "isLockedOut",
      key: "isLockedOut",
      responsive: ["lg"] as Breakpoint[],
      sorter: (a: any, b: any) => {
        return a.isLockedOut === b.isLockedOut ? 0 : a.isLockedOut ? -1 : 1;
      },
      render: (value: boolean, record: any) => {
        return value ? "Yes" : "No";
      },
    },

    {
      title: "Disable",
      key: "isDisabled",
      responsive: ["md"] as Breakpoint[],
      render: (text: any, record: any) => (
        <Button
          size="middle"
          // className="a-button v-button-warning"
          onClick={(e) => handleDisableClick(e, record)}
        >
          {record.isDisabled ? "Enable" : "Disable"}
        </Button>
      ),
      width: "180px",
      sorter: (a: any, b: any) => {
        return a.isDisabled - b.isDisabled;
      },
    },
    ...additionalColumns,
  ];

  return (
    <Table
      style={{ wordBreak: "break-word" }}
      size="small"
      onRow={handleRowClick}
      className="mb-s"
      rowKey={(record) => record.id}
      dataSource={list}
      columns={columns}
      loading={isLoading}
      pagination={{ defaultPageSize: 50 }}
    />
  );
};

export default UserList;

export interface IUserListProps {
  list: Array<any>;
  bindData: () => void;
  handleRowClick: (record: any) => any;
  isLoading: boolean;
  additionalColumns?: Array<any>;
}
