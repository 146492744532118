import React, { useState, useEffect, useRef } from "react";

import { Layout, Row, Col, Button } from "antd";
import { useApp } from "../../../../AppContext";
import { useParams } from "react-router-dom";
import moment from "moment";
import ManifestPassengerCard from "./ManifestPassengerCard";
import Disclaimer from "./Disclaimer";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

import * as WetwheelsApi from "../../../../client-swagger/api";

const ManifestPrint = () => {
    const [bookingData, setBookingData] = useState(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { authHeaderOptions, notifyError } = useApp();

    const { id } = useParams() as any;

    const fetchBookingData = async (bookingId: string) => {
        setIsLoading(true);

        const { success, data, message }: WetwheelsApi.ApiResponse =
            await new WetwheelsApi.BookingApi().booking(
                bookingId,
                authHeaderOptions,
            );
        setIsLoading(false);
        if (!success) {
            notifyError(message);
            return;
        }
        setBookingData(data);
    };

    useEffect(() => {
        fetchBookingData(id);
    }, []);

    const printDocument = () => {
        const input = document.getElementById("divToPrint");
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF({ compress: true });

            var width = pdf.internal.pageSize.getWidth();
            var height = pdf.internal.pageSize.getHeight();

            pdf.addImage(imgData, "PNG", 0, 0, width, height);
            pdf.save(`ww-booking-${bookingData.uniqueKey}.pdf`);
        });
    };

    return (
        bookingData && (
            <>
                <Row justify="center">
                    <Button className="mt-m" onClick={printDocument}>
                        Download pdf
                    </Button>
                </Row>
                <Layout id="divToPrint" className="p-xl layout-white">
                    <Layout.Header
                        className="mb-m"
                        style={{
                            padding: 0,
                            background: "none",
                            width: "100%",
                        }}
                    >
                        <img
                            src="images/logo-jersey.png"
                            alt="Wetwheels"
                            className="logo"
                        />
                    </Layout.Header>
                    <Layout.Content>
                        <Row>
                            <Col span={24}>
                                <h4>Booking Summary</h4>
                                <Row style={{ marginBottom: "10px" }}>
                                    <Col md={8} xs={24}>
                                        <p style={{ fontSize: "19px" }}>
                                            Booking Reference:
                                        </p>
                                    </Col>
                                    <Col md={8} xs={24}>
                                        {bookingData.uniqueKey}
                                    </Col>
                                </Row>
                                <Row style={{ marginBottom: "10px" }}>
                                    <Col md={8} xs={24}>
                                        <p style={{ fontSize: "19px" }}>
                                            Skipper:
                                        </p>
                                    </Col>
                                    <Col md={8} xs={24}>
                                        {bookingData.skipper
                                            ? `${bookingData.skipper.user.firstName} ${bookingData.skipper.user.lastName}`
                                            : "Skipper not assigned"}
                                    </Col>
                                </Row>
                                <Row style={{ marginBottom: "10px" }}>
                                    <Col md={8} xs={24}>
                                        <p style={{ fontSize: "19px" }}>
                                            Date:
                                        </p>
                                    </Col>
                                    <Col md={8} xs={24}>
                                        {moment(
                                            bookingData.startDatetime,
                                        ).format("MMMM Do YYYY")}
                                        ,{" "}
                                        {moment(
                                            bookingData.startDatetime,
                                        ).format("LT")}{" "}
                                        -{" "}
                                        {moment(bookingData.endDatetime).format(
                                            "LT",
                                        )}
                                    </Col>
                                </Row>
                                <Row style={{ marginBottom: "10px" }}>
                                    <Col md={8} xs={24}>
                                        <p style={{ fontSize: "19px" }}>
                                            Activity:
                                        </p>
                                    </Col>
                                    <Col md={8} xs={24}>
                                        {bookingData.eventType.name}
                                    </Col>
                                </Row>
                                <Row style={{ marginBottom: "10px" }}>
                                    <Col md={8} xs={24}>
                                        <p style={{ fontSize: "19px" }}>
                                            Name:
                                        </p>
                                    </Col>
                                    <Col md={8} xs={24}>
                                        {bookingData.createdByUser.firstName}{" "}
                                        {bookingData.createdByUser.lastName}
                                    </Col>
                                </Row>
                                <Row style={{ marginBottom: "10px" }}>
                                    <Col md={8} xs={24}>
                                        <p style={{ fontSize: "19px" }}>
                                            Organisation:
                                        </p>
                                    </Col>
                                    <Col md={8} xs={24}>
                                        {bookingData.createdByUser
                                            .organisationName
                                            ? bookingData.createdByUser
                                                  .organisationName
                                            : "No organsation listed"}
                                    </Col>
                                </Row>
                                <Row style={{ marginBottom: "10px" }}>
                                    <Col md={8} xs={24}>
                                        <p style={{ fontSize: "19px" }}>
                                            Status:
                                        </p>
                                    </Col>
                                    <Col md={8} xs={24}>
                                        {bookingData.status}
                                    </Col>
                                </Row>
                                <Row style={{ marginBottom: "10px" }}>
                                    <Col md={8} xs={24}>
                                        <p style={{ fontSize: "19px" }}>
                                            Email:
                                        </p>
                                    </Col>
                                    <Col md={8} xs={24}>
                                        {bookingData.createdByUser.email}
                                    </Col>
                                </Row>
                                <Row style={{ marginBottom: "10px" }}>
                                    <Col md={8} xs={24}>
                                        <p style={{ fontSize: "19px" }}>
                                            Contact Number:
                                        </p>
                                    </Col>
                                    <Col md={8} xs={24}>
                                        {
                                            bookingData.createdByUser
                                                .contactNumber
                                        }
                                    </Col>
                                </Row>
                                <Row style={{ marginBottom: "10px" }}>
                                    <Col md={8} xs={24}>
                                        <p style={{ fontSize: "19px" }}>
                                            No. Passengers:
                                        </p>
                                    </Col>
                                    <Col md={8} xs={24}>
                                        {bookingData.numberOfPassengers
                                            ? bookingData.numberOfPassengers
                                            : "Not listed at initial booking stage"}
                                    </Col>
                                </Row>
                                <Row style={{ marginBottom: "10px" }}>
                                    <Col md={8} xs={24}>
                                        <p style={{ fontSize: "19px" }}>
                                            No. Wheelchairs:
                                        </p>
                                    </Col>
                                    <Col md={8} xs={24}>
                                        {bookingData.numberOfWheelchairs
                                            ? bookingData.numberOfWheelchairs
                                            : "Not listed at initial booking stage"}
                                    </Col>
                                </Row>
                                <Row style={{ marginBottom: "10px" }}>
                                    <Col md={8} xs={24}>
                                        <p style={{ fontSize: "19px" }}>
                                            No. Special Needs:
                                        </p>
                                    </Col>
                                    <Col md={8} xs={24}>
                                        {bookingData.numberOfDisabledNonWheelchair
                                            ? bookingData.numberOfDisabledNonWheelchair
                                            : "Not listed at initial booking stage"}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} className="mt-m">
                                <h4>Manifest</h4>
                            </Col>
                        </Row>
                        {bookingData.manifest &&
                        bookingData.manifest.passengers ? (
                            <Row className="mb-m mt-m" gutter={16}>
                                <Col span={24}>
                                    <ManifestPassengerCard
                                        bookingData={bookingData}
                                    />
                                </Col>
                            </Row>
                        ) : (
                            <p>No passengers have been added to the manifest</p>
                        )}
                        <Row>
                            <Col span={24} className="mb-s">
                                <h4>Disclaimer</h4>
                            </Col>
                        </Row>
                        {bookingData.disclaimerSignedDate ? (
                            <Disclaimer bookingData={bookingData} />
                        ) : (
                            <p>Disclamer not signed</p>
                        )}
                    </Layout.Content>
                </Layout>
            </>
        )
    );
};

export default ManifestPrint;
