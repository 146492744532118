import {
    Layout,
    Row,
    Col,
    Form,
    Input,
    Button,
    notification,
    Alert,
    Card,
    Drawer,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useApp } from "../../../../AppContext";
import * as WetwheelsApi from "../../../../client-swagger/api";
import CombinedBookingSummary from "./CombinedBookingSummary";
import ReadOnlyManifest from "./ReadOnlyManifest";

const CombinedBookingManifest = ({ summary }: any) => {
    let { id } = useParams() as any;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [bookingKeys, setBookingKeys] = useState<string[]>(null);
    const [bookingData, setBookingData] = useState(null);
    const { authHeaderOptions, notifyError, notifySuccess } = useApp();

    const fetchBookingData = async (bookingId: string) => {
        setIsLoading(true);

        const { success, data, message }: WetwheelsApi.ApiResponse =
            await new WetwheelsApi.BookingApi().booking(
                bookingId,
                authHeaderOptions,
            );

        console.log(data);

        setIsLoading(false);
        if (!success) {
            notifyError(message);
            return;
        }
        let keys: string[] = [];
        keys.push(data.uniqueKey);
        keys = [
            ...keys,
            ...data.subBookings.map((booking: any) => booking.uniqueKey),
        ];
        console.log(keys);
        setBookingKeys(keys);

        setBookingData(data);
    };

    useEffect(() => {
        // fetch data
        fetchBookingData(id);
    }, []);

    return (
        bookingData && (
            <>
                <Layout style={{ backgroundColor: "white", padding: 30 }}>
                    <Layout.Header
                        className="mb-m"
                        style={{
                            padding: 0,
                            background: "none",
                            width: "100%",
                        }}
                    >
                        <h3>
                            Manifest for booking(s): {bookingKeys.join(", ")}
                        </h3>
                    </Layout.Header>
                    <Layout.Content>
                        <Row
                            justify="space-between"
                            className="mb-m"
                            gutter={16}
                        >
                            <Col span={24}>
                                <CombinedBookingSummary
                                    summary={bookingData.combinedBookingSummary}
                                    mainBookingId={bookingData.id}
                                    hideManifestButton={true}
                                ></CombinedBookingSummary>
                            </Col>
                        </Row>

                        <ReadOnlyManifest
                            booking={bookingData}
                        ></ReadOnlyManifest>

                        {bookingData.subBookings.map((booking: any) => (
                            <ReadOnlyManifest
                                key={booking.id}
                                booking={booking}
                            ></ReadOnlyManifest>
                        ))}
                    </Layout.Content>
                </Layout>
            </>
        )
    );
};

export default CombinedBookingManifest;
