import React, { useEffect } from "react";
import { Collapse, Button, Row, Col } from "antd";
import { useApp } from "../../../../../AppContext";
import DetailsCollapsePanelPassengerInfo from "./DetailsCollapsePanelPassengerInfo";

const { Panel } = Collapse;
const PassengerDetailCollapsePanel = ({
    passengers,
    isDisabled,
    deleteHander,
    updateHandler,
}: IPassengerDetailCollapseProps) => {
    const { manifestData } = useApp();

    useEffect(() => {}, [manifestData]);

    return (
        <Collapse>
            {manifestData.passengers.map((passenger: any) => (
                <Panel
                    header={`${passenger.firstName} ${passenger.lastName}`}
                    key={passenger.id}
                >
                    <DetailsCollapsePanelPassengerInfo
                        label="First Name"
                        value={passenger.firstName}
                    />
                    <DetailsCollapsePanelPassengerInfo
                        label="Last Name"
                        value={passenger.lastName}
                    />
                    <Row className="mt-xxs">
                        <Col className="my-xxs" xs={24} md={12}>
                            <p>Is Wheelchair User</p>
                        </Col>
                        <Col className="my-xxs" xs={24} md={12}>
                            {passenger.isWheelchairUser === true ? "Yes" : "No"}
                        </Col>
                    </Row>
                    <Row className="mt-xxs">
                        <Col className="my-xxs" xs={24} md={12}>
                            <p>Is Special Needs User</p>
                        </Col>
                        <Col className="my-xxs" xs={24} md={12}>
                            {passenger.isDisabledNonWheelchair === true
                                ? "Yes"
                                : "No"}
                        </Col>
                    </Row>
                    <Row className="mt-xxs">
                        <Col className="my-xxs" xs={24} md={12}>
                            <p>Has Given Photo Consent</p>
                        </Col>
                        <Col className="my-xxs" xs={24} md={12}>
                            {passenger.hasGivenPhotoConsent === true
                                ? "Yes"
                                : "No"}
                        </Col>
                    </Row>
                    <Row className="mt-xxs">
                        <Col className="my-xxs" xs={24} md={12}>
                            <p>Is Repeat User</p>
                        </Col>
                        <Col className="my-xxs" xs={24} md={12}>
                            {passenger.isRepeatUser === true ? "Yes" : "No"}
                        </Col>
                    </Row>
                    <DetailsCollapsePanelPassengerInfo
                        label="Age range"
                        value={passenger.ageRangeDescription}
                    />
                    <DetailsCollapsePanelPassengerInfo
                        label="Gender"
                        value={passenger.gender}
                    />

                    <Row>
                        <Col span={24}>
                            <Button
                                style={{ width: "180px" }}
                                onClick={() => updateHandler({ ...passenger })}
                            >
                                Update passenger
                            </Button>
                        </Col>
                        <Col span={24}>
                            <Button
                                style={{ width: "180px" }}
                                className="mt-xs"
                                danger
                                htmlType="button"
                                onClick={() => deleteHander(passenger.id)}
                            >
                                Delete passenger
                            </Button>
                        </Col>
                    </Row>
                </Panel>
            ))}
        </Collapse>
    );
};

export default PassengerDetailCollapsePanel;

export interface IPassengerDetailCollapseProps {
    passengers: any[];
    isDisabled: boolean;
    deleteHander?: (id: string) => void;
    updateHandler?: (passenger: any) => void;
}
