import React, { useState, useEffect, useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import {
  Layout,
  notification,
  Col,
  Row,
  Table,
  Spin,
  Button,
  Input,
} from "antd";
import { Redirect, useParams } from "react-router-dom";
import { useApp } from "../../../../AppContext";
import * as WetwheelsApi from "../../../../client-swagger/api";
import { ApiResponse } from "../../../../client-swagger/api";
import ReactSignatureCanvas from "react-signature-canvas";

function DashboardBookingClientDisclaimer() {
  const [redirect, setRedirect] = useState<boolean>(false);
  const { jwtToken, userName, authHeaderOptions } = useApp();
  const [manifestData, setManifestData] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [name, setName] = useState<string>(null);

  const { id } = useParams();

  useEffect(() => {
    if (!name) {
      setName(userName);
    }
  }, [userName]);

  const reloadManifest = () => {
    new WetwheelsApi.ManifestApi()
      .manifestBookingIdGet(id, authHeaderOptions)
      .then(({ data, success }: ApiResponse) => {
        if (success) {
          setManifestData(data);
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!id) {
      return;
    }
    // get data and store in a useState
    reloadManifest();
  }, [id]);

  const showSuccess = (message?: string) => {
    notification.success({
      message: "Success",
      description: message,
    });
  };

  const showError = (message?: string) => {
    notification.error({
      message: "Error",
      description: message,
    });
  };

  const columns = [
    {
      title: "First name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Photo consent?",
      dataIndex: "hasGivenPhotoConsent",
      key: "hasGivenPhotoConsent",
      render: (value: boolean) => {
        return value ? "Yes" : "No";
      },
    },
  ];

  let sigPad: ReactSignatureCanvas = null;

  const handleClearSig = () => {
    if (sigPad) {
      sigPad.clear();
    }
  };

  const handleSaveForm = () => {
    // actaully do stuff :Facepalm:

    new WetwheelsApi.BookingApi()
      .disclaimerComplete(
        {
          bookingId: id,
          disclaimertext:
            "This should be read from settings, somewhere, somehow",
          signatureBase64String: sigPad.toDataURL(),
          signedBy: name,
        },
        authHeaderOptions
      )
      .then(({ success, message }: ApiResponse) => {
        if (success) {
          showSuccess("Thank you for signing the disclaimer");
          setTimeout(() => {
            setRedirect(true);
          }, 2000);
          return;
        }

        showError(message);
      });
  };

  const topRef = useRef<HTMLDivElement>();

  useEffect(() => {
    topRef.current.scrollIntoView();
  }, []);

  const handleNameChange = (e: any) => {
    setName(e.target.value);
  };

  return redirect ? (
    <Redirect to={`/dashboard/bookings/${id}`} />
  ) : (
    <div ref={topRef}>
      <Spin spinning={isLoading}>
        <Layout>
          <Layout.Content>
            <Row gutter={[16, 52]}>
              <Col md={{ span: 18 }} xs={{ span: 24 }}>
                <h3>RISK ACKNOWLEDGEMENT &amp; DISCLAIMER</h3>
                <p>
                  Due to the nature of the activity, you are requested to read
                  the following and sign to confirm that you have received and
                  understood it along with our terms and conditions.
                </p>
                <p>
                  Please ask if there is anything that you do not understand or
                  require further information.
                </p>
                <p>
                  The Skipper/Crew will deliver a full safety briefing prior to
                  departure for your own safety, and will instruct on how to
                  board, where to sit and what to do in an emergency. If you do
                  not fully understand what you have been told please ask.
                </p>
                <p>
                  It must be recognised that Powerboating by its nature is an
                  unpredictable sport and therefore inherently involves an
                  element of risk with being on the water. By taking part and
                  coming aboard Wetwheels, you agree and acknowledge that:
                </p>
                <ul className="mb-l" style={{ listStyle: "decimal" }}>
                  <li>
                    You are aware of the inherent element of risk involved and
                    you accept responsibility for yourself and for the
                    individual(s) in your care
                  </li>
                  <li>
                    The skipper is advised of any concerns you have prior to
                    boarding
                  </li>
                  <li>
                    The wearing of Lifejackets is mandatory on all trips. For
                    those of you who are able-bodied or wheelchair users who do
                    not use a chest strap, your lifejackets are automatically
                    self-inflating and in the unlikely event you find yourself
                    in the water, your lifejacket will automatically inflate, if
                    it doesn’t you’ll need to pull the red manual inflation
                    toggle. If you are a wheelchair user who does use a chest
                    strap, you will be fitted with a manual inflating
                    lifejacket. This is for your safety and to prevent injury in
                    the unlikely event the lifejacket self inflates in a
                    non-emergency situation. You or your caregiver will be given
                    instructions on what to do in the event of an emergency and
                    how to pull the red manual inflation toggle. Please do not
                    pull the red inflation toggles in any non-emergency
                    situation. Lifejackets will require adjustment to fit
                    correctly and our Skipper and Crew will assist as necessary.
                  </li>
                  <li>
                    You will remain seated whilst underway and hold on firmly at
                    all times. If at anytime you or a member or your group feels
                    unwell, uncomfortable, experience any pain or feel any
                    distress, please notify the skipper/crew immediately.
                  </li>
                  <li>
                    You accept responsibility for any injury, damage or loss to
                    the extent caused by your own negligence. You may get wet
                    while participating in this activity: This can vary
                    considerably according to the weather conditions or sea
                    state on the day and is completely beyond our control. Spray
                    capes can be provided to cover your clothes, if you would
                    like one, please ask.
                  </li>
                </ul>
                <h3>CONSENT TO POWERBOATING ACTIVITIES</h3>
                <p>
                  I agree that my group/family, named above may take part in a
                  trip on Wetwheels and that the particulars given above are
                  correct and complete in all respects.
                </p>
                <p>
                  I confirm that, on behalf of my group/family, and myself we
                  have read and fully understand the above and the Terms and
                  Conditions and agree to abide by them and will comply with all
                  the instructions given to us.
                </p>
                <p>
                  I also have authority to sign on behalf of my group/family.
                </p>
              </Col>
              <Col md={{ span: 18 }} xs={{ span: 24 }}>
                <h3>PHOTOGRAPHY</h3>
                <p>
                  Everyone has a great time on board and we like to use
                  photographs on our social media pages to show people what we
                  do.
                </p>
                <p>
                  You understand that you can withdraw your consent at any time
                  but acknowledge that once on social media, Wetwheels Jersey
                  cannot reasonably be expected to remain in control of the
                  images. If you wish to take your own photographs, please be
                  mindful of our other participants and ensure that only the
                  children under your direct care are pictured in the image.
                </p>
                {manifestData && manifestData.passengers && (
                  <Table
                    pagination={false}
                    dataSource={manifestData.passengers}
                    columns={columns}
                  />
                )}
              </Col>
              <Col md={{ span: 18 }} xs={{ span: 24 }}>
                <div className="mb-m">
                  <h3>I have read and acknowledge this disclaimer</h3>
                  <p>Please print your full name</p>

                  <Col md={{ span: 9 }} xs={{ span: 12 }}>
                    <Input
                      size="large"
                      onChange={handleNameChange}
                      name="name"
                      placeholder="Please enter your name"
                    />
                  </Col>

                  <p>Please use your mouse or finger to sign your consent</p>
                  <SignatureCanvas
                    ref={(ref) => {
                      sigPad = ref;
                    }}
                    penColor="grey"
                    canvasProps={{
                      width: 700,
                      height: 250,
                      className: "a-sigCanvas",
                    }}
                  />
                </div>
                <Button onClick={handleClearSig} htmlType="button" size="small">
                  Clear and start again
                </Button>
              </Col>
            </Row>
          </Layout.Content>
          <Layout.Footer
            style={{ padding: 0, background: "none", width: "100%" }}>
            {/* <Link to="/dashboard/bookings/detail?id=JRY9764">
            <button className="a-button v-button-primary">Back</button>
          </Link> */}
            <Button
              className="a-button v-button-primary "
              onClick={handleSaveForm}
              htmlType="button">
              Save
            </Button>
          </Layout.Footer>
        </Layout>
      </Spin>
    </div>
  );
}

export default DashboardBookingClientDisclaimer;
