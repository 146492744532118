import { Alert, Col, Descriptions, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const CombinedBookingSummary = ({ summary, mainBookingId, hideManifestButton }: any) => {

    return (
        <Alert
            type={summary.status == "Confirmed" ? "success" : "warning"}
            message="Combined booking summary"
            description={(
                <Descriptions>
                    <Descriptions.Item span={24} label="Date and time">
                        {moment(summary.startDatetime).format("dddd, MMMM Do YYYY, HH:mm")}
                    </Descriptions.Item>

                    <Descriptions.Item span={24} labelStyle={{ fontWeight: "bold" }} label="Total bookings">
                        {summary.numberOfBookings}
                    </Descriptions.Item>
                    <Descriptions.Item span={24} label="Status">
                        {summary.status}
                    </Descriptions.Item>
                    <Descriptions.Item span={24} label="Number of passengers">
                        {summary.numberOfPassengers}
                    </Descriptions.Item>
                    <Descriptions.Item span={24} label="Number of wheelchairs">
                        {summary.numberOfWheelchairs}
                    </Descriptions.Item>
                    <Descriptions.Item span={24} label="Number of disabled, non wheelchair users">
                        {summary.numberOfDisabledNonWheelchair}
                    </Descriptions.Item>
                    {!hideManifestButton && <Descriptions.Item span={24}>
                        <Link to={`/dashboard/bookings/${mainBookingId}/combinedmanifest`}>
                            <button className="a-button v-button-secondary my-xs">
                                View Combined Manifest
                            </button>
                        </Link>
                    </Descriptions.Item>}
                </Descriptions>
            )}
        ></Alert>
    )
}

export default CombinedBookingSummary;