import React, { useState, useEffect } from "react";
import { Layout, Drawer, notification, Table, Row, Col, Button } from "antd";
import DashboardEventTypeDetail from "./DashboardEventTypeDetail";

import * as WetwheelsApi from "../../../client-swagger/api";
import IEventType from "../../../interfaces/IEventType";
import { useApp } from "../../../AppContext";

function EventTypeList() {
    const [data, setData] = useState<string>(null);
    const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
    const [eventListLoading, setEventListLoading] = useState<boolean>(true);
    const [eventList, setEventList] = useState(null);
    const { authHeaderOptions } = useApp();

    let disable = false;

    const showDrawer = (eventTypeId: string) => {
        if (eventTypeId) {
            setData(eventTypeId);
        }
        setDrawerVisible(true);
    };

    const onClose = () => {
        setData(null);
        setDrawerVisible(false);
        fetchEventData();
    };

    const handleNewEventClick = () => {
        setData(null);
        setDrawerVisible(true);
    };

    const handleRow = (record: IEventType) => {
        return {
            onClick: () => {
                if (disable) return;
                showDrawer(record.id);
            },
        };
    };

    const handleDisableClick = async (record: any) => {
        disable = true;

        const { data, code } =
            await new WetwheelsApi.EventApi().deleteEventType(
                record.id,
                authHeaderOptions,
            );

        if (code !== 200) return;
        fetchEventData();
        notification.success({
            message: "Success",
            description: "Event Type removed",
            duration: 1.5,
        });
    };

    const columns = [
        {
            title: "Event name",
            dataIndex: "name",
            key: "eventName",
            sorter: (a: any, b: any) => {
                return a.name.localeCompare(b.name) as number;
            },
        },
        {
            title: "No of slots",
            dataIndex: "noOfSlotsRequired",
            key: "noOfSlots",
            sorter: (a: any, b: any) => {
                return a.noOfSlotsRequired - b.noOfSlotsRequired;
            },
        },
        {
            title: "",
            key: "action",
            render: (record: any) => (
                <Button
                    style={{ float: "right" }}
                    size="middle"
                    onClick={() => handleDisableClick(record)}
                >
                    Delete
                </Button>
            ),
        },
    ];

    const fetchEventData = async () => {
        const { code, data } =
            await new WetwheelsApi.EventApi().activityTypes();

        setEventListLoading(false);
        if (code !== 200) return;

        setEventList(data);
    };

    useEffect(() => {
        fetchEventData();
        //add condition for rerender upon having added new event
    }, []);

    return (
        <>
            <Layout>
                <Layout.Header
                    className="mb-m m-small-screen-stack v-ant-layout-header"
                    style={{ padding: 0, background: "none", width: "100%" }}
                >
                    <Row justify="space-between" align="middle">
                        <Col>
                            <h2 className="mr-m">Event types</h2>
                        </Col>
                        <Col>
                            <button
                                className="a-button v-button-primary"
                                onClick={handleNewEventClick}
                            >
                                Add new event type
                            </button>
                        </Col>
                    </Row>
                </Layout.Header>
                <Layout.Content>
                    <Table
                        onRow={handleRow}
                        className="mb-s"
                        rowKey={(record) => record.id}
                        columns={columns}
                        dataSource={eventList}
                        loading={eventListLoading}
                    />
                </Layout.Content>
            </Layout>
            <Drawer
                width={"75vw"}
                onClose={onClose}
                destroyOnClose={true}
                visible={drawerVisible}
                bodyStyle={{ paddingBottom: 80 }}
            >
                <DashboardEventTypeDetail
                    eventTypeId={data}
                    onClose={onClose}
                />
            </Drawer>
        </>
    );
}

export default EventTypeList;
