import React from "react";
import { Row, Col, Form, DatePicker, Input } from "antd";
import moment from "moment";

export interface IManifestPassengerListProps {
  bookingData: any;
}

const Disclaimer = ({ bookingData }: IManifestPassengerListProps) => {
  return (
    <Row>
      <Col span={24}>
        {/* [TODO] - complete disclaimer process */}
        <Form.Item label="Date signed">
          <DatePicker
            format="DD/MM/YYYY"
            defaultValue={moment(bookingData.disclaimerSignedDate)}
            showTime={false}
            disabled
            className="v-remove-box"
          />
        </Form.Item>
        <Form.Item label="Signed by">
          <Input
            defaultValue={bookingData.disclaimerSignedBy}
            disabled
            className="v-remove-box"
          />
        </Form.Item>
        <Form.Item label="Signature">
          <img
            src={bookingData.disclaimerSignatureImage}
            id="signature"
            className="a-sigCanvas"
            style={{
              width: 500,
              height: 200,
            }}
            alt="signature"
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default Disclaimer;
