import React, { useEffect, useState } from "react";
import { Layout, Row, Col, Table, Select, Tabs } from "antd";
import moment from "moment";
import { useHistory } from "react-router";

import * as WetwheelsApi from "../../../../client-swagger/api";
import { useApp } from "../../../../AppContext";
import { ApiResponse } from "../../../../client-swagger/api";
import AddToCalendar from "react-add-to-calendar";
import BookingStatusIndicator from "../../../../components/Booking/BookingStatusIndicator";
import { Breakpoint } from "antd/lib/_util/responsiveObserve";
import HandleBookingDecision from "../Skipper/HandleBookingDecision";
import useLocalStorage from "../../../../hooks/useLocalStorageState";
import ICalendarEventDetails from "../../../../interfaces/ICalendarEventDetails";
import User from "../../../../enums/User";

const DashboardBookingsSkipperOrCrew = () => {
    const history = useHistory();
    const { createAddToCalEvent, authHeaderOptions, userType } = useApp();

    const [isUnconfirmedListLoading, setIsUnconfirmedListLoading] =
        useState<boolean>(true);
    const [unconfirmedList, setUnconfirmedList] = useState<Array<any>>(null);

    const [isConfirmedListLoading, setIsConfirmedListLoading] =
        useState<boolean>(true);
    const [confirmedList, setConfirmedList] = useState<Array<any>>(null);

    const [otherConfirmedList, setOtherConfirmedList] =
        useState<Array<any>>(null);

    const [, setConfirmedListLoading] = useState<boolean>(false);

    const [filterAllBookings, setFilterAllBookings] = useLocalStorage(
        "_bookings_filterAll",
        false,
    );
    const [filterFutureBookings, setFilterFutureBookings] = useLocalStorage(
        "_bookings_filterFuture",
        false,
    );

    const [filterListDates, setFilterListDates] = useState(null);

    const [, setIsLoading] = useState(true);

    const [, setIsAllListLoading] = useState<boolean>(true);

    const [startDate, setStartDate] = useLocalStorage(
        "_bookings_startDate",
        moment().startOf("isoWeek").toDate(),
    );

    let disableTables = false;

    // ONLY CREATE THE COLUMNS ONCE, THEN USE THEM IN EACH TABLE?!
    const keyedColumns: any = {};

    keyedColumns["name"] = {
        title: "Name",
        dataIndex: "firstName",
        key: "firstName",
        render: (text: string, record: any) => {
            return record.firstName + " " + record.lastName;
        },
    };
    keyedColumns["organisation"] = {
        title: "Organisation",
        dataIndex: "organisation",
        key: "organisation",
        responsive: ["lg"] as Breakpoint[],
    };
    keyedColumns["date"] = {
        title: "Date",
        dataIndex: "startDateTime",
        key: "startDateTime",
        render: (text: string, record: any) => {
            return `${moment(text).format("dddd")}, ${moment(
                record.startDateTime,
            ).format("LL")}: ${moment(record.startDateTime).format(
                "LT",
            )} to ${moment(record.endDateTime).format("LT")}`;
        },
    };
    keyedColumns["activity"] = {
        title: "Activity",
        dataIndex: "activityName",
        key: "activityName",
    };
    keyedColumns["reference"] = {
        responsive: ["lg"] as Breakpoint[],
        title: "Reference",
        dataIndex: "uniqueKey",
        key: "uniqueKey",
    };
    keyedColumns["skipper"] = {
        title: "Skipper",
        dataIndex: "skipper",
        key: "skipper",
        render: (text: string, record: any) => {
            return record.skipperFirstName + " " + record.skipperLastName;
        },
    };
    keyedColumns["crew"] = {
        title: "Crew",
        dataIndex: "crew",
        key: "crew",
        render: (text: string, record: any) => {
            return record.crewFirstName
                ? record.crewFirstName + " " + record.crewLastName
                : "None";
        },
    };
    keyedColumns["acceptAction"] = {
        title: "",
        key: "action",
        responsive: ["lg"] as Breakpoint[],
        render: (text: string, record: any) => (
            <HandleBookingDecision
                bookingId={record.bookingId}
                disableTables={true}
                bindUpcomingUnconfirmed={bindUpcomingUnconfirmed}
                bindAllConfirmed={bindAllConfirmed}
                accept={true}
            />
        ),
    };
    keyedColumns["rejectAction"] = {
        title: "",
        key: "action",
        responsive: ["lg"] as Breakpoint[],
        render: (text: string, record: any) => (
            <HandleBookingDecision
                bookingId={record.bookingId}
                disableTables={true}
                bindUpcomingUnconfirmed={bindUpcomingUnconfirmed}
                bindAllConfirmed={bindAllConfirmed}
                accept={false}
            />
        ),
    };

    keyedColumns["status"] = {
        title: "Status",
        dataIndex: "status",
        key: "status",
        responsive: ["lg"] as Breakpoint[],
        render: (text: string, record: any) => (
            <BookingStatusIndicator
                percentage={record.statusPercentage}
            ></BookingStatusIndicator>
        ),
    };
    keyedColumns["addToCalendar"] = {
        title: "Add to calendar",
        dataIndex: "addToCal",
        key: "addToCal",
        responsive: ["sm"] as Breakpoint[],
        render: (text: any, record: any) => (
            <>
                <button className="a-button v-button-primary mr-m">
                    <AddToCalendar
                        buttonLabel="Add to Calendar"
                        event={createAddToCalEvent({
                            contactNumber: record.contactNumber,
                            uniqueKey: record.uniqueKey,
                            firstName: record.firstName,
                            lastName: record.lastName,
                            email: record.email,
                            organisation: record.organisation,
                            startTime: record.startDateTime,
                            endTime: record.endDateTime,
                            eventType: record.activityName,
                            noOfPassengers: record.numberOfPassengers,
                            noOfWheelschairs: record.numberOfWheelchairs,
                            noOfNonWheelchairDisabled:
                                record.numberOfDisabledNonWheelchair,
                            additionalInformation: record.additionalInformation,
                        } as ICalendarEventDetails)}
                    />
                </button>
            </>
        ),
        width: "180px",
    };
    keyedColumns["skipper"] = {
        title: "Skipper",
        dataIndex: "skipper",
        key: "skipper",
        render: (text: string, record: any) => {
            return record.skipperFirstName + " " + record.skipperLastName;
        },
    };

    const handleBookingDetail = (record: any) => {
        return {
            onClick: (event: any) => {
                if (event.target.closest("button")) {
                    return;
                }

                if (disableTables) return;
                const ref = `/dashboard/bookings/${record.bookingId}`;
                history.push(ref);
            },
        };
    };

    const getStartDate = async () => {
        const date = filterAllBookings
            ? moment(filterListDates[0]).format()
            : filterFutureBookings
            ? moment().format()
            : startDate
            ? moment(startDate).format()
            : moment().format();

        //console.log("date", date)
        return date;
    };

    const getDuration = async () => {
        const length = filterAllBookings || filterFutureBookings ? 9999 : 7;
        //console.log("length", length);
        return length;
    };

    const bindUpcomingUnconfirmed = () => {
        try {
            if (userType === User.Crew) {
                new WetwheelsApi.BookingApi()
                    .listCrewUnconfirmedBookings(authHeaderOptions)
                    .then(({ code, data }: ApiResponse) => {
                        if (code === 200) {
                            //console.log("unconfirmed data", data);
                            setUnconfirmedList(data);
                        }
                        setIsUnconfirmedListLoading(false);
                    });
            } else {
                // all upcoming unconfirmed bookings
                new WetwheelsApi.BookingApi()
                    .listBookingsByStatus(
                        "unconfirmed",
                        moment().format(),
                        365,
                        authHeaderOptions,
                    ) // get a year of unconfirmed bookings!
                    .then(({ code, data }: ApiResponse) => {
                        if (code === 200) {
                            setUnconfirmedList(data);
                        }
                        setIsUnconfirmedListLoading(false);
                    });
            }
        } catch (err) {
            throw new Error(err);
        }
    };

    const bindAllConfirmed = async () => {
        setIsAllListLoading(true);

        try {
            if (userType === User.Skipper) {
                const { data } =
                    await new WetwheelsApi.BookingApi().skipperConfirmedBookingsByDate(
                        await getStartDate(),
                        await getDuration(),
                        true,
                        authHeaderOptions,
                    );
                setIsConfirmedListLoading(false);
                setConfirmedList(data);
            } else {
                const { data } =
                    await new WetwheelsApi.BookingApi().listCrewConfirmedBookings(
                        true,
                        "status not used",
                        await getStartDate(),
                        await getDuration(),
                        authHeaderOptions,
                    );
                //console.log("data", data);
                setIsConfirmedListLoading(false);
                setConfirmedList(data);
            }
        } catch (err) {
            throw new Error(err);
        }
    };

    const bindOtherConfirmed = async () => {
        try {
            if (userType === User.Skipper) {
                const { data } =
                    await new WetwheelsApi.BookingApi().skipperConfirmedBookingsByDate(
                        await getStartDate(),
                        await getDuration(),
                        false,
                        authHeaderOptions,
                    );

                setConfirmedListLoading(false);

                setOtherConfirmedList(data);
            } else {
                const { data } =
                    await new WetwheelsApi.BookingApi().listCrewConfirmedBookings(
                        false,
                        "status not used",
                        await getStartDate(),
                        await getDuration(),
                        authHeaderOptions,
                    );

                setConfirmedListLoading(false);

                setOtherConfirmedList(data);
            }
        } catch (err) {
            throw new Error(err);
        }
    };

    const handleFilterChange = (value: any) => {
        if (value) {
            setIsLoading(true);

            if (value === "ALL") {
                setStartDate(null);
                setFilterAllBookings(true);
                setFilterFutureBookings(false);
            } else if (value === "FUTURE") {
                setStartDate(null);
                setFilterAllBookings(false);
                setFilterFutureBookings(true);
            } else {
                setFilterAllBookings(false);
                setFilterFutureBookings(false);
                setStartDate(moment(value).toDate());
            }
        }
    };

    const fetchMinMaxDates = async () => {
        try {
            const { data } =
                await new WetwheelsApi.BookingApi().bookingMinMaxDatesGet(
                    authHeaderOptions,
                );

            const min = moment(data.minDate).startOf("isoWeek");
            const max = moment(data.maxDate).startOf("isoWeek");

            let start = min;
            let dates = [];
            while (start <= max) {
                dates.push(start.toDate());
                start = start.add(7, "days");
            }

            setFilterListDates(dates);
        } catch (err) {
            throw new Error(err);
        }
    };

    useEffect(() => {
        const startUp = async () => {
            await fetchMinMaxDates();
            setIsLoading(false);
        };

        startUp();
    }, []);

    useEffect(() => {
        const loadReload = async () => {
            setIsLoading(true);
            // all upcoming unconfirmed bookings
            await bindUpcomingUnconfirmed();
            // all skippers confirmed bookings
            await bindAllConfirmed();
            // bind the other's bookings
            await bindOtherConfirmed();
            setIsLoading(false);
        };

        if (filterListDates) {
            loadReload();
        }
    }, [startDate, filterListDates, filterAllBookings, filterFutureBookings]);

    return (
        // <div>not authenticated</div>
        // window.location.assign("/login")
        <>
            <Layout>
            

                <Layout.Header
                    className="mb-m mt-m  v-ant-layout-header"
                    style={{ padding: 0, background: "none", width: "100%" }}
                >
                    <Row
                        justify="space-around"
                        className="m-small-screen-stack"
                    >
                        <Col span={24}>
                            <h2 className="float-left">Confirmed bookings</h2>
                        </Col>

                        <Col span={24}>
                            {filterListDates && (
                                <Row>
                                    <Col xs={24} md={12} lg={6}>
                                        Week commencing:{" "}
                                    </Col>
                                    {/* TODO Sort out filtering and showing the bookings in that week */}
                                    <Col lg={12} xs={24}>
                                        <Select
                                            style={{ width: 300 }}
                                            defaultValue={
                                                startDate
                                                    ? moment(startDate)
                                                          .startOf("isoWeek")
                                                          .format()
                                                    : "ALL"
                                            }
                                            onChange={handleFilterChange}
                                        >
                                            <Select.Option value="FUTURE">
                                                FUTURE BOOKINGS
                                            </Select.Option>
                                            <Select.Option value="ALL">
                                                ALL BOOKINGS
                                            </Select.Option>
                                            <Select.Option
                                                key={`thisweek-${new Date().toISOString()}`}
                                                value={moment()
                                                    .startOf("isoWeek")
                                                    .format()}
                                            >
                                                THIS WEEK'S BOOKINGS
                                            </Select.Option>
                                            {filterListDates.map(
                                                (date: Date) => (
                                                    <Select.Option
                                                        key={date.toISOString()}
                                                        value={moment(
                                                            date,
                                                        ).format()}
                                                    >
                                                        {moment(date)
                                                            .startOf("isoWeek")
                                                            .format(
                                                                "DD/MM/YYYY",
                                                            )}
                                                    </Select.Option>
                                                ),
                                            )}
                                        </Select>
                                    </Col>
                                </Row>
                            )}
                        </Col>
                    </Row>
                </Layout.Header>
                <Layout.Content>
                    <Row gutter={[16, 16]}>
                        <Col md={{ span: 24 }}>
                            <Tabs defaultActiveKey="1">
                                <Tabs.TabPane tab="Your bookings" key="1">
                                    <Table
                                        onRow={handleBookingDetail}
                                        rowKey={(record) => record.bookingId}
                                        columns={
                                            userType === User.Crew
                                                ? [
                                                      keyedColumns["name"],
                                                      keyedColumns[
                                                          "organisation"
                                                      ],
                                                      keyedColumns["reference"],
                                                      keyedColumns["date"],
                                                      keyedColumns["activity"],
                                                      keyedColumns["status"],
                                                      keyedColumns["skipper"],
                                                      keyedColumns[
                                                          "addToCalendar"
                                                      ],
                                                  ]
                                                : [
                                                      keyedColumns["name"],
                                                      keyedColumns[
                                                          "organisation"
                                                      ],
                                                      keyedColumns["reference"],
                                                      keyedColumns["date"],
                                                      keyedColumns["activity"],
                                                      keyedColumns["status"],
                                                      keyedColumns["crew"],
                                                      keyedColumns[
                                                          "addToCalendar"
                                                      ],
                                                  ]
                                        }
                                        dataSource={confirmedList}
                                        pagination={false}
                                        loading={isConfirmedListLoading}
                                    />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Others bookings" key="2">
                                    <Table
                                        onRow={(record) => {
                                            return {
                                                onClick: (event) => {
                                                    const ref = `/dashboard/bookings/${record.bookingId}`;
                                                    history.push(ref);
                                                },
                                            };
                                        }}
                                        rowKey={(record) => record.bookingId}
                                        columns={
                                            userType === User.Crew
                                                ? [
                                                      keyedColumns["name"],
                                                      keyedColumns[
                                                          "organisation"
                                                      ],
                                                      keyedColumns["reference"],
                                                      keyedColumns["date"],
                                                      keyedColumns["activity"],
                                                      keyedColumns["status"],
                                                      keyedColumns["skipper"],
                                                      keyedColumns["crew"],
                                                  ]
                                                : [
                                                      keyedColumns["name"],
                                                      keyedColumns[
                                                          "organisation"
                                                      ],
                                                      keyedColumns["reference"],
                                                      keyedColumns["date"],
                                                      keyedColumns["activity"],
                                                      keyedColumns["status"],
                                                      keyedColumns["skipper"],
                                                      keyedColumns["crew"],
                                                  ]
                                        }
                                        dataSource={otherConfirmedList}
                                        pagination={false}
                                        loading={isConfirmedListLoading}
                                    />
                                </Tabs.TabPane>
                            </Tabs>
                        </Col>
                    </Row>
                </Layout.Content>


                <Layout.Header
                    className="mb-m"
                    style={{ padding: 0, background: "none", width: "100%" }}
                >
                    <h2 className="float-left">Unconfirmed bookings</h2>
                </Layout.Header>
                <Layout.Content>
                    <Row gutter={[16, 16]}>
                        <Col md={{ span: 24 }}>
                            {unconfirmedList && unconfirmedList.length ? (
                                <Table
                                    onRow={handleBookingDetail}
                                    loading={isUnconfirmedListLoading}
                                    pagination={false}
                                    rowKey={(record) => record.bookingId}
                                    columns={
                                        userType === User.Crew
                                            ? [
                                                  keyedColumns["name"],
                                                  keyedColumns["organisation"],
                                                  keyedColumns["reference"],
                                                  keyedColumns["date"],
                                                  keyedColumns["skipper"],
                                                  keyedColumns["activity"],
                                                  keyedColumns["acceptAction"],
                                                  keyedColumns["rejectAction"],
                                              ]
                                            : [
                                                  keyedColumns["name"],
                                                  keyedColumns["organisation"],
                                                  keyedColumns["reference"],
                                                  keyedColumns["date"],
                                                  keyedColumns["crew"],
                                                  keyedColumns["activity"],
                                                  keyedColumns["acceptAction"],
                                                  keyedColumns["rejectAction"],
                                              ]
                                    }
                                    dataSource={unconfirmedList}
                                />
                            ) : (
                                <p>
                                    There are currently no unconfirmed bookings
                                    matching your availability
                                </p>
                            )}
                        </Col>
                    </Row>
                </Layout.Content>
            </Layout>
        </>
    );
};

export default DashboardBookingsSkipperOrCrew;
