import React from "react";
import { useApp } from "../../AppContext";
import User from "../../enums/User";

function DashboardHome() {
  const { userType } = useApp();

  let userString = "";

  switch (userType) {
    case User.Admin:
      userString = "Admin";
      break;
    case User.Skipper:
      userString = "Skipper";
      break;
    case User.Client:
      userString = "Client";
      break;
  }

  return (
    <>
      <h2>Welcome, {userString}</h2>
    </>
  );
}

export default DashboardHome;
