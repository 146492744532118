import React from "react";

import UserAdd from "../../../components/User/Add/UserAdd";

const DashboardClientsAdd = ({ closeWindow }: IDashboardClientAddProps) => {
    return <UserAdd closeWindow={closeWindow} role="client" />;
};

export default DashboardClientsAdd;

interface IDashboardClientAddProps {
    closeWindow: () => void;
}
