import React from "react";

import UserAdd from "../../../components/User/Add/UserAdd";

const DashboardAdminAdd = ({ closeWindow }: IDashboardClientAddProps) => {
    return <UserAdd closeWindow={closeWindow} role="admin" />;
};

export default DashboardAdminAdd;

interface IDashboardClientAddProps {
    closeWindow: () => void;
}
