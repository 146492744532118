import React, { useState, useEffect } from "react";
import {
    Layout,
    notification,
    Form,
    Input,
    DatePicker,
    Select,
    Spin,
    Modal,
    Switch,
    Alert,
    Row,
    Col,
    Divider,
    Typography,
    Space,
    Menu,
    Dropdown,
    message,
} from "antd";
import { PlusOutlined, DownOutlined } from "@ant-design/icons";
import { Link, Redirect, useParams } from "react-router-dom";
import moment from "moment";
import { useApp } from "../../../../AppContext";

import PassengerDetailCollapsePanel from "../Client/Passenger/PassengerDetailCollapsePanel";
import * as WetwheelsApi from "../../../../client-swagger/api";
import { ApiResponse, PassengerManifest } from "../../../../client-swagger/api";
import ManifestAddOrUpdate from "./ManifestAddOrUpdate";
import { FormInstance } from "antd/lib/form";
import IManifestSummary from "../../../../interfaces/IManifestSummary";

const { Option } = Select;

const DashboardBookingClientManifest = () => {
    const [redirect, setRedirect] = useState<boolean>(false);
    const [showAddPassengerModal, setShowAddPassengerModal] =
        useState<boolean>(false);
    const [isPassengerUpdate, setIsPassengerUpdate] = useState<boolean>(false);
    const {
        userId,
        manifestData,
        setManifestData,
        selectedPassenger,
        setSelectedPassenger,
        authHeaderOptions,
    } = useApp();
    const [bookingData, setBookingData] = useState(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const { id }: any = useParams();

    const reloadManifest = () => {
        new WetwheelsApi.ManifestApi()
            .manifestBookingIdGet(id, authHeaderOptions)
            .then(({ code, data, success, message }: ApiResponse) => {
                if (success) {
                    setManifestData(data);
                } else {
                    // CREATE?
                    new WetwheelsApi.ManifestApi()
                        .manifestCreatePost(
                            {
                                bookingId: id,
                                userId: userId,
                                passengers: [],
                            },
                            authHeaderOptions,
                        )
                        .then(
                            ({ code, data, success, message }: ApiResponse) => {
                                if (success) {
                                    setManifestData(data);
                                } else {
                                    showError(message);
                                }
                            },
                        );
                }
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (!id) {
            return;
        }

        // get data and store in a useState
        new WetwheelsApi.BookingApi()
            .booking(id, authHeaderOptions)
            .then(({ code, data, success, message }: ApiResponse) => {
                if (success) {
                    setBookingData(data);
                } else {
                    showError(message);
                }
            });
    }, [id]);

    useEffect(() => {
        if (!bookingData) {
            return;
        }

        if (bookingData.manifest) {
            // we already have it. let's also return previous manifests here...
            setManifestData(bookingData.manifest);
            setIsLoading(false);
            return;
        }

        // get data and store in a useState
        reloadManifest();
    }, [bookingData]);

    const showSuccess = (message?: string) => {
        notification.success({
            message: "Success",
            description: message,
        });
    };

    const showError = (message?: string) => {
        notification.error({
            message: "Error",
            description: message,
        });
    };

    const handleSaveForm = () => {
        notification.success({
            message: "Success",
            description: "Manifest has been saved",
            duration: 1.5,
            onClose: () => {
                setManifestData(true);
                setRedirect(true);
            },
        });
    };

    const handleAddPassenger = () => {
        setSelectedPassenger(null);
        setShowAddPassengerModal(
            (showAddPassengerModal) => !showAddPassengerModal,
        );
    };

    const handleCancelSavePassenger = () => {
        setShowAddPassengerModal(false);
        setIsPassengerUpdate(false);
    };

    const handleSavePassenger = (values: any) => {
        const passenger: PassengerManifest = values;

        if (isPassengerUpdate) {
            new WetwheelsApi.ManifestApi()
                .manifestManifestIdPassengerUpdatePassengerIdPatch(
                    selectedPassenger.id,
                    selectedPassenger.manifestId,
                    passenger,
                    authHeaderOptions,
                )
                .then(({ data, success, message }: ApiResponse) => {
                    if (success) {
                        showSuccess(message);
                        // addPassengerForm.resetFields();
                        setShowAddPassengerModal(false);
                        setIsLoading(true);
                        setIsPassengerUpdate(false);
                        reloadManifest();
                    } else {
                        showError("Please complete manifest before submitting");
                    }
                });
        } else {
            passenger.manifestId = manifestData.id;
            // get data and store in a useState
            new WetwheelsApi.ManifestApi()
                .manifestAddPassengerPost(passenger, authHeaderOptions)
                .then(({ code, data, success, message }: ApiResponse) => {
                    if (success) {
                        showSuccess(message);
                        //addPassengerForm.resetFields();
                        setShowAddPassengerModal(false);
                        setIsLoading(true);
                        reloadManifest();
                        // reload the manifest!
                    } else {
                        showError("Please complete manifest before submitting");
                    }
                });
        }
    };

    const handleDeletePassenger = (passengerId: string) => {
        const manifestId = manifestData.id;

        new WetwheelsApi.ManifestApi()
            .manifestManifestIdPassengerPassengerIdDelete(
                passengerId,
                manifestId,
                authHeaderOptions,
            )
            .then(({ code, data, success, message }: ApiResponse) => {
                if (success) {
                    showSuccess(message);
                    setIsLoading(true);
                    reloadManifest();
                } else {
                    showError(message);
                }
            });
    };

    const handleUpdatePassenger = (passenger: any) => {
        setSelectedPassenger(passenger);
        setShowAddPassengerModal(true);
        setIsPassengerUpdate(true);
    };

    const handlePreviousManifestSelect = (e: any) => {
        console.log(bookingData.id);
        console.log(e.key);
        setIsLoading(true);
        new WetwheelsApi.BookingApi()
            .bookingDuplicatemanifestBookingIdManifestIdPost(
                bookingData.id,
                e.key,
                authHeaderOptions,
            )
            .then(({ code, data, success, message }: ApiResponse) => {
                if (success) {
                    showSuccess(message);

                    reloadManifest();
                } else {
                    showError(message);
                }
            });
        setIsLoading(false);
    };

    const MenuForPreviousManifests =
        bookingData &&
        bookingData.previousManifests &&
        bookingData.previousManifests.length
            ? () => {
                  console.log("menu");
                  const data =
                      bookingData.previousManifests as IManifestSummary[];

                  const menu = (
                      <Menu onClick={handlePreviousManifestSelect}>
                          {data.map((m: IManifestSummary) => {
                              let names = m.passengerNames.join(", ");
                              names = names.slice(0, names.length - 2);

                              return (
                                  <Menu.Item key={m.id}>
                                      <Typography.Title level={3}>
                                          {" "}
                                          {m.bookingKey} on{" "}
                                          {moment(m.dateOfBooking).format(
                                              "DD/MM/yyyy",
                                          )}{" "}
                                          with {m.passengerNames.length}{" "}
                                          passengers
                                      </Typography.Title>
                                      <p>{names}</p>
                                      <Divider />
                                  </Menu.Item>
                              );
                          })}
                      </Menu>
                  );

                  return (
                      <Row>
                          <Col span={24}>
                              <div className="dropdown-container">
                                  <Dropdown overlay={menu} trigger={["click"]}>
                                      <a
                                          className="ant-dropdown-link"
                                          onClick={(e) => e.preventDefault()}
                                      >
                                          Select a previous manifest to load the
                                          passengers <DownOutlined />
                                      </a>
                                  </Dropdown>
                              </div>
                          </Col>
                      </Row>
                  );
              }
            : () => (
                  <Row>
                      <Col span={24}>
                          <div className="dropdown-container">
                              <p>No previous manifests</p>
                          </div>
                      </Col>
                  </Row>
              );

    return redirect ? (
        <Redirect to={`/dashboard/bookings/${id}`} />
    ) : (
        <>
            <Spin spinning={isLoading}>
                {bookingData && manifestData ? (
                    <Layout>
                        <Layout.Header
                            className="mb-m"
                            style={{
                                padding: 0,
                                background: "none",
                                width: "100%",
                            }}
                        >
                            <h2>
                                Manifest for booking {bookingData.uniqueKey}
                            </h2>
                        </Layout.Header>
                        <Layout.Content>
                            {manifestData &&
                            manifestData.passengers &&
                            manifestData.passengers.length ? (
                                <PassengerDetailCollapsePanel
                                    passengers={manifestData.passengers}
                                    isDisabled={true}
                                    deleteHander={handleDeletePassenger}
                                    updateHandler={handleUpdatePassenger}
                                ></PassengerDetailCollapsePanel>
                            ) : (
                                <>
                                    <Alert
                                        type="warning"
                                        message="You have not yet added any passengers"
                                        description="Please use the 'Add Passenger' button below to add passengers to your manifest, or select one of your previous manifests to copy the passenger list to this manifest."
                                    />
                                    <MenuForPreviousManifests />
                                </>
                            )}

                            <button
                                className="a-button v-button-primary mt-m"
                                onClick={handleAddPassenger}
                            >
                                Add passenger
                            </button>
                        </Layout.Content>
                        <Layout.Footer
                            style={{
                                padding: "30px 0 0",
                                background: "none",
                                width: "100%",
                            }}
                        >
                            <Link to={`/dashboard/bookings/${bookingData.id}`}>
                                <button className="a-button v-button-primary mb-m">
                                    Back
                                </button>
                            </Link>
                            <button
                                className="a-button v-button-primary mb-m float-right"
                                onClick={handleSaveForm}
                            >
                                Save
                            </button>
                        </Layout.Footer>
                    </Layout>
                ) : null}

                <ManifestAddOrUpdate
                    showAddPassengerModal={showAddPassengerModal}
                    handleCancelSavePassenger={handleCancelSavePassenger}
                    handleSavePassenger={handleSavePassenger}
                ></ManifestAddOrUpdate>
            </Spin>
        </>
    );
};

export default DashboardBookingClientManifest;
