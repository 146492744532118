import React, { useState } from "react";
import { Table } from "antd";
import BookingStatusIndicator from "./BookingStatusIndicator";
import moment from "moment";
import { Redirect } from "react-router";

const BookingList = ({ bookings }: IBookingListProps) => {
    const [redirect, setRedirect] = useState<string>("");

    const columns = [
        {
            title: "Reference",
            dataIndex: "uniqueKey",
            key: "uniqueKey",
        },
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
            render: (text: string, row: any) => {
                return moment(row.date).format("LL");
            },
        },
        {
            title: "Time",
            dataIndex: "startDateTime",
            key: "startDateTime",
            render: (text: string, row: any) => {
                return `${moment(row.startDateTime).format("LT")} - ${moment(
                    row.endTime,
                ).format("LT")} `;
            },
        },
        {
            title: "Activity",
            dataIndex: "activityName",
            key: "activityName",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (text: string, record: any) => (
                <BookingStatusIndicator
                    percentage={record.statusPercentage}
                ></BookingStatusIndicator>
            ),
        },
    ];

    const handleRow = (row: any) => {
        return {
            onClick: () => {
                setRedirect(`/dashboard/bookings/${row.bookingId}`);
            },
        };
    };

    return redirect ? (
        <Redirect to={redirect} />
    ) : (
        <>
            <Table
                columns={columns}
                dataSource={bookings}
                onRow={handleRow}
            ></Table>
        </>
    );
};

export default BookingList;

export interface IBookingListProps {
    bookings: Array<any>;
}
